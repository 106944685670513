import React, {Component} from 'react';

import './InstructorBadge.css'
import {Badge} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBox} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

class CourseBadge extends Component {
    constructor(props) {
        super(props);
        this.course = props.course;
        this.withLink = false;
    }
    render() {
        return(
            this.withLink?
            <Link to={"/courses/"+this.course._id}>
                <Badge style={{marginRight:'0.2em',marginBottom:'0.2em'}}
                       className={"courseBadge"} bg="dark">
                    <FontAwesomeIcon icon={faBox}/> {this.course.name}
                </Badge>
            </Link>:<Badge style={{marginRight:'0.2em',marginBottom:'0.2em'}}
                           className={"courseBadge"} bg="dark">
                        <FontAwesomeIcon icon={faBox}/> {this.course.name}
                    </Badge>
        )
    }
}
export default CourseBadge;