import React, {Component, useEffect, useState} from "react";
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import logo from "../../assets/bauabc_logo.PNG";
import * as formik from "formik";
import * as yup from "yup";
import APIController from "../../controller/APIController";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHourglassStart} from "@fortawesome/free-solid-svg-icons";
import UserController from "../../controller/UserController";
import {useNavigate} from "react-router-dom";


const UpdatePasswordFormComponent = (props) => {
    const { Formik, Field } = formik;
    const navigate = useNavigate();

    const schema = yup.object().shape({
        oldPassword: yup.string()
            .required("Alt Passwort wird benötigt"),
        newPassword: yup.string()
            .required("Passwort wird benötigt")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Muss 8 Zeichen enthalten, einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen wie !@#$%^&*"
            ),
        repeatNewPassword : yup.string()
            .required("Dieses Feld ist erforderlich")
            .oneOf([yup.ref('newPassword'), null], "Die Passwörter müssen übereinstimmen")
    });
    const [initialValues, setInitialValues] = React.useState({oldPassword:"", newPassword:"", repeatNewPassword: ""});
    const [apiResponseMessage, setApiResponseMessage] = useState("")
    const [inProgress, setInProgress] = useState(false);
    const [alertType, setAlertType] = useState("alert-danger");

    useEffect(() => {
        const errorMessageCleaner = setInterval(() => {
            if(apiResponseMessage !== ""){
                setApiResponseMessage("");
                setAlertType("alert-danger");
            }
        }, 5000);
        return () => clearInterval(errorMessageCleaner);
    }, [apiResponseMessage, alertType]);


    return (
        <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={ async (values, { resetForm }) => {
                try {

                    setApiResponseMessage("");
                    setInProgress(true);

                    let isPasswordUpdated = await APIController.updatePassword(values.oldPassword, values.newPassword);

                    if(isPasswordUpdated.success){
                        setAlertType("alert-success")
                        setApiResponseMessage( `Du hast dein Passwort erfolgreich geändert.`);
                        resetForm();
                        let stepInterval = setInterval(()=>{
                            setInProgress(false);
                            navigate(UserController.getMyCoursesUrl());
                        }, 2000);
                    }
                }
                catch (error){
                    resetForm();
                    setInProgress(false);
                    setApiResponseMessage(error.message);
                }
            }}>
            {({ handleSubmit, setFieldValue, values, handleBlur, touched, errors}) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label className="text-center">
                            Gebe das alte Passwort ein
                        </Form.Label>
                        <Form.Control type="password" placeholder="Altes Passwort" id="oldPassword" value={values.oldPassword}
                                      onBlur={handleBlur}
                                      isInvalid={touched.oldPassword && !!errors.oldPassword}
                                      onChange={(e) => setFieldValue( "oldPassword",e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" >
                            {errors.oldPassword}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="text-center">
                            Lege ein neues Passwort fest
                        </Form.Label>
                        <Form.Control type="password" placeholder="neues Passwort" id="newPassword" value={values.newPassword}
                                      onBlur={handleBlur}
                                      isInvalid={touched.newPassword && !!errors.newPassword}
                                      onChange={(e) => setFieldValue( "newPassword",e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" >
                            {errors.newPassword}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="text-center">
                            Bestätige das Passwort
                        </Form.Label>
                        <Form.Control type="password" placeholder="Passwort eingeben" id="repeatNewPassword" value={values.repeatNewPassword}
                                      onBlur={handleBlur}
                                      isInvalid={touched.repeatNewPassword && !!errors.repeatNewPassword}
                                      onChange={(e) => setFieldValue( "repeatNewPassword",e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" >
                            {errors.repeatNewPassword}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {apiResponseMessage &&
                        <div className={"mt-3 mb-3 alert " + alertType }>{apiResponseMessage}</div>
                    }
                    <div className="d-grid">
                        <div align={'center'}>
                            <div style={{display: 'inline-block', marginRight: '0.5em'}}>
                                {!inProgress ?
                                    <Button
                                        type="submit"
                                        variant="outline-secondary">
                                        Kennwort ändern
                                    </Button> :
                                    <span>Bitte warten <FontAwesomeIcon icon={faHourglassStart}/></span>
                                }
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

class UpdatePassword extends Component {

    constructor(props){
        super(props);
        this.params = props.params;
        this.state = {}
    }


    render() {
        return (
            <>
                <div className="Update-password">
                    <Container>
                        <Row className="vh-100 d-flex justify-content-center align-items-center">
                            <Col md={8} lg={6} xs={12}>
                                <Card className="shadow">
                                    <Card.Body>
                                        <div align={'center'}>
                                            <img src={logo} style={{height: "80px"}} className="App-logo" alt="logo"/>
                                        </div>
                                        <div className="mb-3 mt-md-4">
                                            <div align={'center'}>
                                                <h4 className="fw-bold mb-3 text-uppercase ">
                                                    <b>Kennwort ändern</b>
                                                </h4>
                                            </div>
                                            <div className="mb-3 mt-md-4">
                                                <UpdatePasswordFormComponent state={this.state} />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}

export default UpdatePassword;