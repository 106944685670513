import React, {Component} from "react";
import LoadingScreen from "../../includes/LoadingScreen";
import APIController from "../../controller/APIController";
import './WorksheetPrintPage.css';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faDownload, faFile, faFilePdf, faUpload} from "@fortawesome/free-solid-svg-icons";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import {SizeMe} from "react-sizeme";
import UserController from "../../controller/UserController";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

class WorksheetPrintPage extends Component {
    constructor(props) {
        super(props);
        this.handleExportPDFClicked = this.handleExportPDFClicked.bind(this);
        this.state = {
            worksheetId: props.worksheetId,
            traineeId: props.traineeId,
            trainee: null,
            isFetching: true,
            worksheet : null,
            access_rights: null,
            pageNumber: 1,
            numPages: null
        };
    }

    onDocumentLoad = ({ numPages }) => {
        this.setState({ numPages });
    }

    onDocumentLoadError = ({ e }) => {
        window.alert("Fehler beim Laden der PDF-Datei: "+e.toString())
    }

    async handleExportPDFClicked() {
        this.setState({
            isFetching: true,
        });
        let blob = await fetch(this.state.fileSrc).then(r => r.blob());
        let result = await APIController.sendPDFBlobToDocuware(
            blob,
            this.getFileName(),
            this.state.worksheetId
        );
        this.setState({
            isFetching: false,
        });
        window.alert("Upload finished");
    }

    async componentDidMount(){
        try {
            const worksheetId = encodeURIComponent(this.state.worksheetId);
            const worksheet = await APIController.getWorksheetById(worksheetId);
            const trainee = await APIController.getTraineeById(worksheet.traineeId);
            let pdfBlobFileSrc = await APIController.getPDFFile(this.state.worksheetId);
            this.setState({
                isFetching: false,
                worksheet: worksheet,
                fileSrc : pdfBlobFileSrc,
                trainee:trainee
            });
        } catch (e) {
            console.log(e)
            this.setState({
                isFetching : false,
                fileSrc: null,
                worksheet: null,
                trainee:null
            });
        }
    }

    getFileName(){
        let ws = this.state.worksheet;
        let t = this.state.trainee;
        return t.name+"_"+t.groupName+"_"+ws.courseId+"_"+ws.topic+".pdf";
    }

    getPDFTitle(){
        let ws = this.state.worksheet;
        let t = this.state.trainee;
        return t.name+", "+t.firstname+" | Projekt "+ws.topic+" | Gruppe "+t.groupName;
    }

    render() {
        const { pageNumber, numPages } = this.state;
        if(!this.state.isFetching && this.state.fileSrc == null){
            return <div>Worksheet Not found</div>
        }else{
            return (
                this.state.isFetching ? (<LoadingScreen/>):(
                    <>
                        <div className="WorksheetPrintPage">

                            <div className={"pdf-wrapper"} style={{paddingTop:'1em'}}>
                                <h1><FontAwesomeIcon icon={faFilePdf} /> {this.getPDFTitle()}</h1>
                                <p>
                                    <Button disabled={pageNumber <= 1} variant="primary"  onClick={() => this.setState(prevState => ({
                                        pageNumber: (prevState.pageNumber - 1) >= 1 ? prevState.pageNumber - 1:prevState.pageNumber }))}>
                                        <FontAwesomeIcon icon={faArrowLeft}/>
                                    </Button>
                                    <span style={{padding:'1em'}}>Seite {pageNumber} von {numPages}</span>
                                        <Button disabled={pageNumber >= numPages} variant="primary"  onClick={() => this.setState(prevState => ({
                                            pageNumber: (prevState.pageNumber + 1) <= prevState.numPages ? prevState.pageNumber + 1:prevState.pageNumber }))}>
                                            <FontAwesomeIcon icon={faArrowRight}/>
                                        </Button>
                                    <div className={"buttonPanel"} style={{display: "inline-block"}}>
                                        <Button variant="primary" href={this.state.fileSrc} style={{marginRight:'1em'}} download={this.getFileName()}>
                                            <FontAwesomeIcon icon={faDownload}  /> PDF Download
                                        </Button>
                                        {UserController.getUser().type === 'instructor' &&
                                            <Button variant="primary" onClick={() => {this.handleExportPDFClicked()}}>
                                                <FontAwesomeIcon icon={faUpload} /> Upload to DocuWare
                                            </Button>
                                        }
                                    </div>
                                </p>

                                <SizeMe
                                    monitorHeight
                                    refreshRate={128}
                                    refreshMode={"debounce"}
                                    render={({ size }) => (
                                        <div>
                                            <Document
                                                onLoadSuccess={this.onDocumentLoad}
                                                file={this.state.fileSrc}
                                                onLoadError={this.onDocumentLoadError}
                                            >
                                                <div className={"pdf-file"}>
                                                    <Page width={size.width} pageNumber={pageNumber} />
                                                </div>
                                            </Document>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </>
                )
            );
        }
    }
}

export default WorksheetPrintPage;