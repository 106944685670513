import React, {Component} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import Switch from "react-switch";
class ToggleSwitchFormField extends Component {
    constructor(props) {
        super(props);
        this.title = props.title;
        this.description = props.description;
        this.icon = props.icon;
        this.state = {
            checked: props.checked
        };
        this.style = props.style;
        this._handleChange = this._handleChange.bind(this);
        this.handleChange = props.onChange;
    }

    _handleChange(checked){
        this.setState({ checked });
        this.handleChange(checked);
    }

    render() {

        if(this.icon && !this.description){
            return(
                <span style={this.style}>
                    {this.icon}
                    <Switch onChange={this._handleChange} checked={this.state.checked} />
                </span>
            )
        }else{
            return(
                <Form.Group style={this.style} className="mb-3">
                    <Form.Text className="text-muted" >
                        {this.icon} {this.description}
                    </Form.Text>
                    <div suppressContentEditableWarning={true} contentEditable="true">
                        <div style={{marginTop:"0.5em"}}>
                            <Switch onChange={this._handleChange} checked={this.state.checked} />
                        </div>
                    </div>
                </Form.Group>
            )
        }


    }
}
export default ToggleSwitchFormField;