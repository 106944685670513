import React, { Component } from 'react';

import './EvaluationResultsTable.css';
import EvaluationFormInput from "./WorksheetEditorComponents/EvaluationFormInput";


class EvaluationResultsTable extends Component {

    constructor(props) {
        super(props);
        let evaluation = JSON.parse(JSON.stringify(props.evaluation));
        this.evaluation = EvaluationFormInput.getStringFormattedEvaluation(evaluation);
    }

    render() {
        return (
            <div className={"EvaluationResults"}>
                <table className={"EvaluationResultsTable"}>
                    <tbody>
                    <tr>
                        <td>Arbeitsvorbereitung</td>
                        <td>{EvaluationFormInput.getSimpleEvaluationMeasureName(this.evaluation.arbeitsvorbereitung)}</td>
                    </tr>
                    <tr>
                        <td>Praktische Fertigkeiten</td>
                        <td>
                            {EvaluationFormInput.getPracticalSkillsConclusionOptionsName(this.evaluation.praktische_fertigkeiten.grade)} ({this.evaluation.praktische_fertigkeiten.points} / 25.0 Punkte)
                        </td>
                    </tr>
                    <tr>
                        <td>Arbeitstempo</td>
                        <td>{EvaluationFormInput.getWorkPaceOptionsMeasureName(this.evaluation.arbeitstempo)}</td>
                    </tr>
                    <tr>
                        <td>Arbeitshaltung</td>
                        <td>{EvaluationFormInput.getWorkPostureName(this.evaluation.arbeitshaltung)}</td>
                    </tr>
                    <tr>
                        <td>QS / Dokumentation</td>
                        <td>{EvaluationFormInput.getSimpleEvaluationMeasureName(this.evaluation.qs_dokumentation)}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}


export default EvaluationResultsTable;