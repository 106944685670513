import React, {Component} from 'react';
import {Alert, Button, Col, Row, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowLeft, faArrowRight, faCalendar,
    faCheck, faClock,
    faClone, faClose,
    faCube, faCubes,
    faPencil,
    faPlusSquare, faSheetPlastic,
    faTools,
} from "@fortawesome/free-solid-svg-icons";
import './TemplatesTable.css';

import DateTimeUtil from "../util/DateTimeUtil";
import PagePopup from "./PagePopup";
import APIController from "../controller/APIController";
import SingleSelectInputFormField from "./formFields/SingleSelectInputFormField";

class TemplatesTable extends Component {

    constructor(props) {
        super(props);

        let allTemplatesOptions = this.props.projects.map((t,idx)=>{
            return(
                {name: t.title, value: t.id}
            )
        });

        this.state = {
            projects: this.props.projects,
            allTemplatesOptions: allTemplatesOptions,
            selectedTemplateOption: null,
            popupProps: {
                title: "",
                body: "",
                submitText: "Bestätigen",
                cancelText: "Abbrechen",
                show: false,
                showFooter: true
            },
        };
        this.addProject = this.addProject.bind(this);
        this.addProjectClicked = this.addProjectClicked.bind(this);
    }

    renderCreateTemplatePopupBody(){
        return <>
            <SingleSelectInputFormField
                title={<><FontAwesomeIcon icon={faCubes}/> Übergeordnetes Template <small>(optional)</small></>}
                selectedValue={this.state.selectedTemplateOption != null ? this.state.selectedTemplateOption.value : null}
                options={this.state.allTemplatesOptions}
                onChange={(selectedOption) => {
                    this.setState({
                        selectedTemplateOption: selectedOption,
                    });
                }}
            />
        </>
    }

    renderCloneTemplatePopupBody(){
        return <>
            <SingleSelectInputFormField
                selectedValue={this.state.selectedTemplateOption != null ? this.state.selectedTemplateOption.value : null}
                options={this.state.allTemplatesOptions}
                onChange={(selectedOption) => {
                    this.setState({
                        selectedTemplateOption: selectedOption,
                    });
                }}
            />
        </>
    }


    addProject(project){
        const projects = this.state.projects;
        projects.unshift(project);
        this.setState({
            projects:projects
        });
    }

    async addProjectClicked(parentProjectId = null){
        let jsonResp = await APIController.createTemplate(
            parentProjectId,
            this.props.professionId);
        this.addProject(jsonResp);
    }

    async cloneTemplate(projectId){
        let self = this;
        let ifTemplate = await APIController.cloneTemplates(projectId);
        if(ifTemplate){
            self.addProject(ifTemplate);
        }
        return null
    }

    render() {
        let metaData = {};
        this.state.projects.sort(function compare(a, b) {
            return b.lastEditedTime - a.lastEditedTime
        });
        this.state.projects.map( (project, idx) => {
            metaData[project.id] = {
                'componentsCount':0
            };
            project.sections.map((section,idx) => {
                section.components.map((component,idx) => {
                    metaData[project.id]['componentsCount'] = metaData[project.id]['componentsCount'] + 1;
                });
            });
        });

        return(
            <>
                <PagePopup
                    className={"evaluationPopup"}
                    onClose={()=>{
                        this.setState(prevState => ({
                            popupProps: {
                                ...prevState.popupProps,
                                show: false
                            }
                        }))
                    }}

                    onSave={this.state.popupProps.onSave}
                    saveButtonText={<><FontAwesomeIcon icon={faCheck} /> {this.state.popupProps.submitText}</>}
                    closeButtonText={<><FontAwesomeIcon icon={faClose} /> {this.state.popupProps.cancelText}</>}
                    key={this.state.popupProps.show+"_popup"}
                    {...this.state.popupProps}>
                </PagePopup>
                <Button type="button" className={"btn btn-primary"} variant="primary" onClick={()=>{
                    this.setState(prevState => ({
                        popupProps: {
                            ...prevState.popupProps,
                            title: <><FontAwesomeIcon icon={faPlusSquare} /> Neues Template anlegen</>,
                            body: this.renderCreateTemplatePopupBody(),
                            show: true,
                            onSave:async ()=>{
                                if(this.state.selectedTemplateOption == null) {
                                    await this.addProjectClicked();
                                }else {
                                    await this.addProjectClicked(this.state.selectedTemplateOption.value);
                                }
                                this.setState(prevState => ({
                                    popupProps: {
                                        ...prevState.popupProps,
                                        show: false
                                    }
                                }));
                            }
                        }
                    }))
                }}><FontAwesomeIcon icon={faPlusSquare} /> Neu</Button>

                <Button style={{marginLeft:'1em'}} type="button" className={"btn btn-info"} variant="primary" onClick={()=>{
                    this.setState(prevState => ({
                        popupProps: {
                            ...prevState.popupProps,
                            title: <><FontAwesomeIcon icon={faClone} /> Template klonen</>,
                            body: this.renderCloneTemplatePopupBody(),
                            show: true,
                            onSave:async ()=>{
                                if(this.state.selectedTemplateOption == null) {
                                   window.alert("Bitte Projekt auswählen");
                                }else {
                                    await this.cloneTemplate(this.state.selectedTemplateOption.value)
                                }
                                this.setState(prevState => ({
                                    popupProps: {
                                        ...prevState.popupProps,
                                        show: false
                                    }
                                }));
                            }
                        }
                    }))
                }}><FontAwesomeIcon icon={faClone} /> Klonen</Button>

                {this.state.projects.length === 0 &&
                    <Alert key={'primary'} variant={'primary'} style={{marginTop:'1em'}}>
                       Es wurde noch kein Template angelegt
                    </Alert>
                }
                <Table className={"table-responsive styledTable"}>
                    <tbody>
                    {this.state.projects.map( (project, idx) => {
                        let className='projectRowLink';
                        if(project.id==='8c9c3a30-4181-48da-9eee-35a7b96d54db'){
                            className = 'projectRowLink defaultTemplate'
                        }
                        return (
                            <tr key= {project.id}>
                                <td>
                                    <Row>
                                        <Col xs={12} lg={12}>
                                            <Link className={className} to={"/editor/" + project.id}>
                                                <div>
                                                    <Row>
                                                        <Col lg={1} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                                            <small><FontAwesomeIcon icon={faTools} /> {project.professionId==="-1"?'*':project.professionId}</small>
                                                        </Col>
                                                        <Col xs={12} lg={8} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                                                <div>
                                                                    {project.templatePath.map(function(object, i){
                                                                        return <span key={i} style={{marginRight:'0.5em',display:'inline-block'}}><FontAwesomeIcon icon={faCube} /> {object} <FontAwesomeIcon icon={faArrowRight} /></span>
                                                                    })}
                                                                    <h5 style={{display:'inline-block',marginRight:'0.5em'}}><FontAwesomeIcon icon={faCube} /> {project.title}</h5>
                                                                </div>
                                                        </Col>

                                                        <Col xs={12} lg={3} style={{display:'flex',justifyContent:'right',alignItems:'center'}}>
                                                            <div>
                                                                <div>
                                                                    <span><FontAwesomeIcon icon={faPencil} /> {DateTimeUtil.toDistanceString(project.lastEditedTime)}</span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Link>
                                        </Col>
                                    </Row>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </>
        )
    }
}


export default TemplatesTable;