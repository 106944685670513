import React, {Component} from 'react';

class InputComponent extends Component {
    constructor(props) {
        super(props);
        this.component = props.component;
        this.showEditorView = props.showEditorView;
        this._handleChange = this._handleChange.bind(this);
        this.updateComponent = props.updateComponent;
    }

    _handleChange(id,checked) {
        this.component.data[id] = checked;
        this.updateComponent(this.component);
    };

    /**
     * Implementation required
     */
    renderEditorView() {
        throw new Error('You have to implement the renderEditorView method!');
    }

    /**
     * Implementation required
     */
    renderInputView() {
        throw new Error('You have to implement the renderInputView method!');
    }

    /**
     * Implementation required
     */
    render() {
        return <div>parent</div>
    }

    /**
     * Implementation required
     */
    create() {
        throw new Error('You have to implement the renderInputView method!');
    }

}
export default InputComponent;