import React, {Component} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";

import Multiselect from 'multiselect-react-dropdown';

class MultiSelectInputFormField extends Component {
    constructor(props) {
        super(props);

        this.title = props.title;
        this.description = props.description;
        this.options = props.options; //[{text='',value=''},...]
        if(this.options == null){
            throw new Error("options need to be set");
        }

        var selectedValues = [];
        props.options.map((option, idx) => {
            props.selectedValues.map((selectedValue, idx) => {
                if(option.id == selectedValue.id){
                    selectedValues.push(option);
                }
            });
        });

        this.state = {
            selectedValues: selectedValues,
            options: props.options
        };

        this.onChange = props.onChange;
        this._handleChange = this._handleChange.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onRemove = this.onRemove.bind(this);


    }

    _handleChange(){
    }

    onSelect(selectedValues){
        this.setState({ selectedValues:selectedValues});
        this.onChange(selectedValues);
    }

    onRemove(selectedValues){
        this.setState({ selectedValues:selectedValues});
        this.onChange(selectedValues);
    }

    render() {
        return(
            <Form.Group className="mb-3">
                {this.title && <Form.Label htmlFor="disabledSelect">{this.title}</Form.Label>}
                <div suppressContentEditableWarning={true} contentEditable="false">
                    <Multiselect
                        singleSelect={false}
                        placeholder={"Auswählen"}
                        emptyRecordMsg={"Alles bereits ausgewählt"}
                        options={this.state.options} // Options to display in the dropdown
                        selectedValues={this.state.selectedValues} // Preselected value to persist in dropdown
                        onSelect={this.onSelect} // Function will trigger on select event
                        onRemove={this.onRemove} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                    />
                </div>
                {this.description &&
                    <Form.Text className="text-muted">
                        {this.description}
                    </Form.Text>
                }
            </Form.Group>
        )
    }
}
export default MultiSelectInputFormField;