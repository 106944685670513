import React from 'react';
import {v4 as uuidv4} from "uuid";
import TextComponent from "../input/TextComponent";
import TextInputFormField from "../formFields/TextInputFormField";
import {Badge, Button, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faExternalLink} from "@fortawesome/free-solid-svg-icons";

class ExternalLinkTextComponent extends TextComponent {
    constructor(props) {
        super(props);
        this.component = props.component;
        this.writeModeBasedOnPhase = props.writeModeBasedOnPhase;
        this._handleChange = this._handleChange.bind(this);
    }

    static create(){
        return {
                id: uuidv4(),
                type : "externalLink",
                description: "",
                title: "External-Links",
                data : {},
                config: {
                    externalUrl:"",
                    externalUrlTitle:""
                }
            }
    }

    _handleChange(id,value){
        this.component.config[id] = value;
        this.updateComponent(this.component);
    }

    renderEditorView(){
        return (
            <div>
                <TextInputFormField
                    title={"URL / Externer Link"}
                    rows={1}
                    value={this.component.config.externalUrl}
                    onChange={(value) => this._handleChange('externalUrl',value)}
                    description={"Geben SIe hier die externe URL ein. Bspw. http://www.google.de"}
                />
                <TextInputFormField
                    title={"Titel des externen Links"}
                    rows={1}
                    value={this.component.config.externalUrlTitle}
                    onChange={(value) => this._handleChange('externalUrlTitle',value)}
                    description={"Geben Sie hier einen Namen des Links ein"}
                />
            </div>
        );
    }

    renderInputView(){
        let linkTitle = this.component.config.externalUrlTitle;
        if(linkTitle == undefined || linkTitle == ""){
            linkTitle = this.component.config.externalUrl;
        }
        return (
            <div className={this.component.type}>
                {this.component.config.externalUrl != "" &&
                    <Row>
                        <Col xs={12} lg={5} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <Button title={this.component.config.externalUrl} disabled={!this.writeModeBasedOnPhase} style={{width:'100%',marginRight:'0.5em'}} onClick={() => {
                                window.open(this.component.config.externalUrl,"_blank")
                            }}>
                                <FontAwesomeIcon icon={faExternalLink} /> {linkTitle}
                            </Button>
                            </Col>
                    </Row>
                }
            </div>

        );
    }

    renderInstructorView(){
        let linkTitle = this.component.config.externalUrlTitle;
        if(linkTitle == undefined || linkTitle == ""){
            linkTitle = this.component.config.externalUrl;
        }
        return (
            <div className={this.component.type}>
                {this.component.config.externalUrl != "" &&
                    <Row>
                        <Col xs={12} lg={5} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <Button variant={"secondary"} title={this.component.config.externalUrl} style={{width:'100%',marginRight:'0.5em'}} onClick={() => {
                                window.open(this.component.config.externalUrl,"_blank")
                            }}>
                                <FontAwesomeIcon icon={faExternalLink} /> {linkTitle}
                            </Button>
                        </Col>
                    </Row>
                }
            </div>

        );
    }

    render() {
        if(this.props.instructorMode){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderInstructorView()}
            </div>
        } else if(this.props.showEditorView){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderEditorView()}
            </div>
        }else{
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderInputView(this.instructorMode)}
            </div>
        }
    }
}
export default ExternalLinkTextComponent;