import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faCancel, faClock, faCommenting, faEdit, faPlus, faSave} from "@fortawesome/free-solid-svg-icons";
import DateTimeUtil from "../../util/DateTimeUtil";

import './ComponentFooter.css';
import {random} from "lodash/number";
import {Alert} from "react-bootstrap";

function ActionIconButton({ children, handler }) {
    return (
        <button
            type="button"
            className={"actionButton btn"}
            onClick={() => {handler()}}
        >
            {children}
        </button>
    );
}

class ComponentFooter extends Component {
    constructor(props) {
        super(props);
        this.hasComments = props.hasComments;
        this.hasEvaluation = props.hasEvaluation;
        this.showCommentInputBox = false;
        this.component = props.component;
        this.updateComponent = props.updateComponent;
        this.onCommentsUpdated = props.onCommentsUpdated;

        this.show = this.show.bind(this);
        this.addComment = this.addComment.bind(this);
        this.cancelAddComment = this.cancelAddComment.bind(this);

        this._handleDataChange = this._handleDataChange.bind(this);

        if(!this.component.config.hasOwnProperty("comments")) {
            this.component.config.comments = [];
        }

        this.state = {
            showCommentInputBox: false,
            commentValue : ""
        };
    }

    show(){
        this.setState({showCommentInputBox:true});
    }

    addComment(){
        this.component.config.comments.push({
            dateTime: new Date(),
            text:this.state.commentValue
        })
        this.updateComponent(this.component);
        this.onCommentsUpdated(this.state.commentValue);
        this.setState({
            showCommentInputBox:false,
            commentValue:""
        });
    }

    cancelAddComment(){
        this.setState({
            showCommentInputBox:false,
            commentValue:""
        });
    }

    _handleDataChange(id,value) {
        this.setState({
            commentValue:value.target.value
        })
    };

    render() {
        return(
            <div className={"ComponentFooter"}>
                <div className={"componentHeaderIcons"}>
                    {this.component.config.comments != null && this.component.config.comments.length > 0 &&
                        <div className={"worksheetComments"}>
                            <Alert key={'primary'} variant={'primary'} style={{marginBottom:0}}>
                                <ul>
                                    {this.component.config.comments.map( (comment, idx) => {
                                        return (<>
                                            <li key={idx}>
                                                <span className={"commentDate"}><FontAwesomeIcon icon={faCalendar} /> {DateTimeUtil.getHumanReadableDate(comment.dateTime)}</span>
                                                <span className={"commentTime"}><FontAwesomeIcon icon={faClock} /> {DateTimeUtil.getHumanReadableTime(comment.dateTime)}</span>
                                                <span className={"commentText"}>{comment.text}</span>
                                            </li>
                                        </>)
                                    })}
                                </ul>
                            </Alert>
                        </div>
                    }
                    {this.hasComments && this.state.showCommentInputBox &&
                        <div className={"mt-2 addCommentBox componentIcon"}>
                            <FontAwesomeIcon title={"Kommentar Funktion aktiviert"} icon={faCommenting}/> Kommentar eingeben ...
                            <textarea
                                value={this.state.commentValue}
                                onChange={()=>{}}
                                onChange={(value) => this._handleDataChange('text',value)}
                                className={"form-control"}
                                style={{width:'100%'}} rows={3}>
                             </textarea>
                            <div className={"mt-2"}>
                                <ActionIconButton handler={this.cancelAddComment}><FontAwesomeIcon icon={faCancel} /> Abbrechen</ActionIconButton>
                                <ActionIconButton handler={this.addComment}><FontAwesomeIcon icon={faSave} /> Speichern</ActionIconButton>
                            </div>
                        </div>
                    }
                    {this.hasComments && this.props.instructorMode && !this.state.showCommentInputBox &&
                        <div className={"mt-2"}>
                            <ActionIconButton key={random(0,300)} handler={this.show}><FontAwesomeIcon title={"Kommentar Funktion aktiviert"} icon={faCommenting}/> Kommentieren</ActionIconButton>
                        </div>
                    }
                </div>
            </div>
        )
    }
}
export default ComponentFooter;