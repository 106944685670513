import React, {useState} from "react";
import _ from 'lodash';
import {Button, Form, InputGroup} from "react-bootstrap";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import "./PhaseEditor.css";
import * as ToggleGroup from '@radix-ui/react-toggle-group';

export function PhaseEditor({project, onChange}) {
    const [projectState, setProjectState] = useState(project);

    const allPhases = _.compact(projectState.sections.map(sectionOfProject => sectionOfProject.phase).flat());
    const parentPhases = _.uniqBy(allPhases, e => e.pid);

    //console.log(parentPhases);
    //console.log(allPhases);

    const handleNameChange = (e, pid) => {
        let newName = e.target.value;
        // we also want to check if the name is already taken
        parentPhases.forEach(phase => {
            if (phase.name.toLowerCase() === newName.toLowerCase()) {
                console.log(phase.name);
                newName = "";
                alert("Phase name already taken");
                return ;

            }
        });
        if(newName.length === 0){
            return ;
        }
        setProjectState(prevState => {
            const updatedSections = prevState.sections.map(section => {
                if (section.phase) {
                    section.phase = section.phase.map(phase => {
                        if (phase.pid === pid) {
                            return {...phase, name: newName};
                        }
                        return phase;
                    });
                }
                return section;
            });
            return {...prevState, sections: updatedSections};
        });
        onChange(projectState);
    };


    const handleDelete = (pid) => {
        setProjectState(prevState => {
            const updatedSections = prevState.sections.map(section => {
                if (section.phase) {
                    section.phase = section.phase.filter(phase => phase.pid !== pid);
                }
                return section;
            });
            return {...prevState, sections: updatedSections};
        });
        onChange(projectState);
    };

    const renderInputGroup = (parentPhaseOfSection, parentPhaseOfSectionIndex) => {
        return (
            <div key={parentPhaseOfSectionIndex}>
                <InputGroup className="mb-1">
                    <Form.Control size={"sm"} value={parentPhaseOfSection.name} disabled={parentPhaseOfSection.fromParent} style={parentPhaseOfSection.fromParent ? {border: "1px solid red"} : {}}
                                  onChange={(e) => handleNameChange(e, parentPhaseOfSection.pid)}/>
                    {/*<Button variant="secondary" size={"sm"} onClick={() => handleSubmit(parentPhaseOfSection.pid)}>Submit</Button>*/}
                    <Button variant="outline-danger" style={parentPhaseOfSection.fromParent ? {border: "1px solid red",zIndex: 'auto'} : {zIndex: 'auto'}} size={"sm"} disabled={parentPhaseOfSection.fromParent}
                            onClick={() => handleDelete(parentPhaseOfSection.pid)}><FontAwesomeIcon
                        icon={faTrashCan}/></Button>
                </InputGroup>
                {/*<InputGroup>
                    <ToggleGroup.Root type={"multiple"} className={"ToggleGroup"} aria-label="Phase">
                        {(["", "", "", "", "", "", "", "", "", "", "", "", "",]).map((item, index) => {
                            return (
                                <ToggleGroup.Item className="ToggleGroupItem" value="left" aria-label="Left aligned">
                                    LEFT
                                </ToggleGroup.Item>)
                        })}

                    </ToggleGroup.Root>
                </InputGroup>*/}
            </div>
        )
    }
    return (

        <div>
            { parentPhases.length > 0 &&
                <React.Fragment key={project.id}>
                    <div className={"mt-2"}>
                        <Form.Label>Sektionsphasen</Form.Label>
                    </div>
                    <div className={"mt-0"} style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, minmax(80px, 1fr))',
                        gap: '1rem'
                    }}>
                        {parentPhases.map((parentPhaseOfSection, parentPhaseOfSectionIndex) => (renderInputGroup(parentPhaseOfSection, parentPhaseOfSectionIndex)))}
                    </div>
                </React.Fragment>
            }
            {parentPhases.length == 0 &&
                <div className={"mt-2"}>
                    <Form.Label>Keine Sektionsphasen definiert</Form.Label>
                    <br />
                    <Form.Text>Nutzen Sie Sektions-Phasen um die Lese- & Schreibrechte einzelnenr Sektionen zeitlich zu steuern</Form.Text>
                </div>
            }
        </div>
    )
}

export function SectionPhaseConfigEditor({section, phaseOfSection, phaseOfSectionIndex, onSectionUpdate}) {
    const phaseVisibilityOptions = [
        /*{value: "unset", name: "Unset"},*/
        {value: "H", name: "Versteckt"},
        {value: "L", name: "Nur lesend"},
        {value: "S", name: "Lesen & Schreiben"}
    ];

    const onChange = (e) => {
        let oldSection = section;
        oldSection.phase.forEach((p, index) => {
            if (p.id === phaseOfSection.id) {
                oldSection.phase[index].visibility = e.target.value;
            }
        });
        //this.onSectionUpdate(oldSection);
        onSectionUpdate(oldSection);
    }

    const onSwitchToggle = (value) => {
        let oldSection = section;
        oldSection.phase.forEach((p, index) => {
            if (p.id === phaseOfSection.id) {
                oldSection.phase[index].active = value;
            }
        });
        //this.onSectionUpdate(oldSection);
        onSectionUpdate(oldSection);
    }

    return (
        <>
            <div>
                <FloatingLabel label={phaseOfSection.name}>
                    <Form.Select size={'sm'} key={phaseOfSectionIndex} defaultValue={phaseOfSection.visibility}
                                 disabled={!phaseOfSection.active}
                                 onChange={(e) => onChange(e)}
                    >
                        {
                            phaseVisibilityOptions.map((option, index) => {
                                return (<option key={index} value={option.value}>{option.name}</option>)
                            })
                        }
                    </Form.Select>
                </FloatingLabel>
                <Form.Switch className={"mt-1"} label={"Aktiv"} id={phaseOfSection.id} checked={phaseOfSection.active}
                             onChange={(e) => {
                                 phaseOfSection.active = !phaseOfSection.active;
                                 onSwitchToggle(phaseOfSection.active);
                             }}/>
            </div>
        </>
    )
}

export function PhaseSwitcher({worksheet, onWorksheetUpdated}) {
    const [worksheetState, setWorksheetState] = useState(worksheet);

    const allPhases = _.compact(worksheet.sections.map(sectionOfWorksheet => sectionOfWorksheet.phase).flat());
    const parentPhases = _.uniqBy(allPhases, e => e.pid);
    const onOptionChange = async (e) => {
        const newPhase = e.target.value;
        const updatedWorksheet = {
            ...worksheetState,
            phase: newPhase
        };

        setWorksheetState(updatedWorksheet);
        if (onWorksheetUpdated) {

            onWorksheetUpdated(updatedWorksheet);
        }
    }

    React.useEffect(() => {
        console.log("current worksheet phase ", worksheetState.phase);
    }, [worksheetState.phase]);

    return (parentPhases.length > 0 &&
        <React.Fragment>
            <Form.Select value={worksheetState.phase || ''} onChange={async (e) => onOptionChange(e)}>
                {!worksheetState.phase && <option value="">Unset</option>}
                {parentPhases.map((parentPhaseOfSection, parentPhaseOfSectionIndex) => {
                    return <option key={parentPhaseOfSectionIndex}
                                   value={parentPhaseOfSection.pid}> {parentPhaseOfSection.name} </option>;
                })
                }
            </Form.Select>
        </React.Fragment>
    )
}
