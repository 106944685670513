import React, {Component} from "react";
import {Row, Col, Button, Alert, Badge} from "react-bootstrap";
import SingleSelectInputFormField from "../formFields/SingleSelectInputFormField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowLeft, faArrowRight,
    faBackspace,
    faCancel,
    faCheck,
    faDiagramNext,
    faEdit,
    faSave
} from "@fortawesome/free-solid-svg-icons";
import APIController from "../../controller/APIController";
import LoadingScreen from "../../includes/LoadingScreen";


class EvaluationFormInput extends Component {

    static evaluationKeys = [
        'arbeitsvorbereitung',
        'arbeitstempo',
        'arbeitshaltung',
        'qs_dokumentation'
    ]

    static simpleEvaluationMeasureOptions = [
        {name: '❌ - keine Bewertung', value: "-1"},
        {name: '1 - systematisch u. vollständig', value: '1'},
        {name: '2 - teilweise vollständig', value: '2'},
        {name: '3 - unsystematisch u. unvollständig', value: '3'},
    ]

    static simpleEvaluationMeasureOptionsShort = [
        {name: '❌', value: "-1"},
        {name: '1', value: '1'},
        {name: '2', value: '2'},
        {name: '3', value: '3'},
    ]

    static workPaceOptions = [
        {name: '❌ - keine Bewertung', value: "-1"},
        {name: '1 - zügig', value: '1'},
        {name: '2 - reicht aus', value: '2'},
        {name: '3 - langsam', value: '3'},
    ]

    static practicalSkillsConclusionOptions = [
        {name: '❌ - keine Bewertung', value: "-1"},
        {name: '1 - überdurchschnittlich', value: '1'},
        {name: '2 - durchschnittlich', value: '2'},
        {name: '3 - unterdurchschnittlich', value: '3'},
    ]

    static workPostureOptions = [
        {name: '❌ - keine Bewertung', value: "-1"},
        {name: '1 - gut', value: '1'},
        {name: '2 - zufriedenstellend', value: '2'},
        {name: '3 - nicht zufriedenstellend', value: '3'},
    ]

    static practicalSkillsOptions = [
        {name: '❌ - keine Bewertung', value: "-1.0"},
        {name: '5,0 Punkte', value: '5.0'},
        {name: '4,5 Punkte', value: '4.5'},
        {name: '4,0 Punkte', value: '4.0'},
        {name: '3,5 Punkte', value: '3.5'},
        {name: '3,0 Punkte', value: '3.0'},
        {name: '2,5 Punkte', value: '2.5'},
        {name: '2,0 Punkte', value: '2.0'},
        {name: '1,5 Punkte', value: '1.5'},
        {name: '1,0 Punkte', value: '1.0'},
        {name: '0,5 Punkte', value: '0.5'},
        {name: '0,0 Punkte', value: '0.0'},
    ];

    static practicalSkillsOptionsShort = [
        {name: '❌', value: "-1.0"},
        {name: '5,0', value: '5.0'},
        {name: '4,5', value: '4.5'},
        {name: '4,0', value: '4.0'},
        {name: '3,5', value: '3.5'},
        {name: '3,0', value: '3.0'},
        {name: '2,5', value: '2.5'},
        {name: '2,0', value: '2.0'},
        {name: '1,5', value: '1.5'},
        {name: '1,0', value: '1.0'},
        {name: '0,5', value: '0.5'},
        {name: '0,0', value: '0.0'},
    ];

    constructor(props) {
        super(props);
        this.onFinishEvaluation = props.onFinishEvaluation;
        this.state = {
            isFetching: true
        }
    }

    async componentDidMount() {
        await this.load();
    }

    async load(){
        let evaluation = {};
        let initialEvaluation = {};
        if(this.props.evaluation === undefined && this.props.worksheet !== undefined){
            evaluation = await APIController.getEvaluationsByWorksheet(this.props.worksheet);
            initialEvaluation = JSON.parse(JSON.stringify(evaluation));
        }else{
            evaluation = JSON.parse(JSON.stringify(this.props.evaluation));
            initialEvaluation = JSON.parse(JSON.stringify(this.props.initialEvaluation));
        }

        if(this.props.empty !== undefined){
            initialEvaluation = this.getEmptyEvaluation(initialEvaluation);
            evaluation = this.getEmptyEvaluation(evaluation);
        }else{
            initialEvaluation = EvaluationFormInput.getFormattedEvaluation(initialEvaluation);
            initialEvaluation = EvaluationFormInput.getStringFormattedEvaluation(initialEvaluation);
            evaluation = EvaluationFormInput.getFormattedEvaluation(evaluation);
            evaluation = EvaluationFormInput.getStringFormattedEvaluation(evaluation);
        }

        this.mode = this.props.mode?this.props.mode:"shortView";

        this.setState({
            isFetching: false,
            evaluation: evaluation,
            initialEvaluation: initialEvaluation
        });
    }


    static getSimpleEvaluationMeasureName(value){
        if(value === -1){
            return null;
        }else{
            let item = EvaluationFormInput.simpleEvaluationMeasureOptions.find(obj => obj.value === ''+value);
            return item.name.substring(4);
        }
    }
    static getWorkPostureName(value){
        if(value === -1){
            return null;
        }else{
            let item = EvaluationFormInput.workPostureOptions.find(obj => obj.value === ''+value);
            return item.name.substring(4);
        }
    }
    static getPracticalSkillsConclusionOptionsName(value){
        if(value === -1){
            return null;
        }else{
            let item = EvaluationFormInput.practicalSkillsConclusionOptions.find(obj => obj.value === ''+value);
            return item.name.substring(4);
        }
    }
    static getWorkPaceOptionsMeasureName(value){
        if(value === -1){
            return null;
        }else{
            let item = EvaluationFormInput.workPaceOptions.find(obj => obj.value === ''+value);
            return item.name.substring(4);
        }
    }

    handleCancelEvaluation = async function () {
        this.onFinishEvaluation(this.state.initialEvaluation);
        this.setState({
            evaluation: this.state.initialEvaluation,
        })
    }.bind(this)

    handleSaveEvaluation = async function () {


        //evaluations[this.state.worksheet.traineeId] = evaluation;
        //await APIController.updateEvaluationsByCourseAndTopic(this.state.worksheet.courseId, this.state.worksheet.topic, evaluations);
        //let updatedWorksheet = await APIController.getWorksheetById(this.state.worksheet.id);

        /*
        let updatedWorksheet = await APIController.getWorksheetById(this.state.worksheet.id);
        if(updatedWorksheet.state === StatusBadge.TYPES.GRADED_WORKING_SHEET.state){

            if(!this.allFieldsAreSet()){
                updatedWorksheet.state = StatusBadge.TYPES.SUBMITTED_WORKING_SHEET.state;
                let wsUpdated = await APIController.updateWorksheetV2(updatedWorksheet);
            }

        }else{
            if(this.allFieldsAreSet()){
                updatedWorksheet.state = StatusBadge.TYPES.GRADED_WORKING_SHEET.state;
                let wsUpdated = await APIController.updateWorksheetV2(updatedWorksheet);
            }
        }

        */

        this.recalcalculateSumView();

        this.onFinishEvaluation(this.state.evaluation);
        this.setState({
            evaluation: this.state.evaluation,
        })
    }.bind(this)


    static recalculatePraktischeFetigkeitenSumResult(evaluation){
        let atLeastOneValueIsPresent = false;
        let allPoints = 0.0;

        evaluation.praktische_fertigkeiten.unterfaecher.map((praktische_fertigkeit,index) => {
            let val  = parseFloat(praktische_fertigkeit.value);
            if(!isNaN(val) && val !== -1 && val !== -1.0){
                atLeastOneValueIsPresent = true;
                allPoints += val;
            }
        });
        if(!atLeastOneValueIsPresent){
            evaluation.praktische_fertigkeiten.points = -1;
            evaluation.praktische_fertigkeiten.grade = -1;
        }else{
            evaluation.praktische_fertigkeiten.points = allPoints;
            if(allPoints >= 22.5){
                evaluation.praktische_fertigkeiten.grade = 1;
            }else if(allPoints >= 12.5){
                evaluation.praktische_fertigkeiten.grade = 2;
            }else{
                evaluation.praktische_fertigkeiten.grade = 3;
            }
        }
        return evaluation;
    }

    getEmptyEvaluation(evaluation){
        let formattedEvaluation = evaluation;
        formattedEvaluation.arbeitsvorbereitung = null;
        formattedEvaluation.arbeitstempo = null;
        formattedEvaluation.arbeitshaltung = null;
        formattedEvaluation.qs_dokumentation = null;

        formattedEvaluation.praktische_fertigkeiten.unterfaecher.map((praktische_fertigkeit,index) => {
            praktische_fertigkeit.title = praktische_fertigkeit.title?praktische_fertigkeit.title:null;
            praktische_fertigkeit.value = null;
        });
        formattedEvaluation.praktische_fertigkeiten.points = null;
        formattedEvaluation.praktische_fertigkeiten.grade = null;
        return formattedEvaluation;
    }

    static getFormattedEvaluation(evaluation){
        let formattedEvaluation = evaluation;
        formattedEvaluation.arbeitsvorbereitung = parseInt(formattedEvaluation.arbeitsvorbereitung);
        formattedEvaluation.arbeitstempo = parseInt(formattedEvaluation.arbeitstempo);
        formattedEvaluation.arbeitshaltung = parseInt(formattedEvaluation.arbeitshaltung);
        formattedEvaluation.qs_dokumentation = parseInt(formattedEvaluation.qs_dokumentation);

        formattedEvaluation.praktische_fertigkeiten.unterfaecher.map((praktische_fertigkeit,index) => {
            praktische_fertigkeit.title = praktische_fertigkeit.title?praktische_fertigkeit.title:null;
            praktische_fertigkeit.value = parseFloat(praktische_fertigkeit.value);
        });
        formattedEvaluation.praktische_fertigkeiten.points = parseFloat(formattedEvaluation.praktische_fertigkeiten.points);
        formattedEvaluation.praktische_fertigkeiten.grade = parseInt(formattedEvaluation.praktische_fertigkeiten.grade);
        return formattedEvaluation;
    }

    static getStringFormattedEvaluation(evaluation){
        let formattedEvaluation = evaluation;
        formattedEvaluation.arbeitsvorbereitung = formattedEvaluation.arbeitsvorbereitung+"";
        formattedEvaluation.arbeitstempo = formattedEvaluation.arbeitstempo+"";
        formattedEvaluation.arbeitshaltung = formattedEvaluation.arbeitshaltung+"";
        formattedEvaluation.qs_dokumentation = formattedEvaluation.qs_dokumentation+"";

        formattedEvaluation.praktische_fertigkeiten.unterfaecher.map((praktische_fertigkeit,index) => {
            praktische_fertigkeit.title = praktische_fertigkeit.title?praktische_fertigkeit.title:"";
            praktische_fertigkeit.value = praktische_fertigkeit.value.toFixed(1);
        });
        formattedEvaluation.praktische_fertigkeiten.points = formattedEvaluation.praktische_fertigkeiten.points.toFixed(1);
        formattedEvaluation.praktische_fertigkeiten.grade = formattedEvaluation.praktische_fertigkeiten.grade+"";
        return formattedEvaluation;
    }

    getTextFromPoints(points){
        switch (points){
            case "1":
                return "1 - überdurchschnittlich";
            case "2":
                return "2 - durchschnittlich";
            case "3":
                return "3 - unterdurchschnittlich";
            case 1:
                return "1 - überdurchschnittlich";
            case 2:
                return "2 - durchschnittlich";
            case 3:
                return "3 - unterdurchschnittlich";
        }
        return "❌"
    }


    renderSimpleEvaluationInputBlock(title, parameterName, shortTitle){

        let allOptions = JSON.parse(JSON.stringify(EvaluationFormInput.simpleEvaluationMeasureOptionsShort));
        if(this.mode === "LargeView"){
            if(parameterName === "arbeitsvorbereitung"){
                allOptions = JSON.parse(JSON.stringify(EvaluationFormInput.simpleEvaluationMeasureOptions));
            }else if(parameterName === "arbeitstempo"){
                allOptions = JSON.parse(JSON.stringify(EvaluationFormInput.workPaceOptions));
            }else if(parameterName === "arbeitshaltung"){
                allOptions = JSON.parse(JSON.stringify(EvaluationFormInput.workPostureOptions));
            }else if(parameterName === "qs_dokumentation"){
                allOptions = JSON.parse(JSON.stringify(EvaluationFormInput.simpleEvaluationMeasureOptions));
            }
        }

        if(this.props.empty !== undefined){
            if (!allOptions.some(obj => obj.value === null)) {
                allOptions.unshift({name: '≡', value: null})
            }
        }

        var self = this;
        let valueChanged = false;
        if(this.state.initialEvaluation[parameterName] !== this.state.evaluation[parameterName]){
            valueChanged = true;
        }
        return (<>

            {this.mode === "LargeView" &&
                <Col xs={12} md={6} style={{display:'flex',justifyContent:'left',alignItems:'center',marginBottom:'0.2em'}}>
                    <span style={{fontWeight:'bold'}}>{title}</span>
                </Col>
            }

            <Col xs={this.mode==="LargeView"?12:4}  md={this.mode==="LargeView"?6:3} lg={this.mode==="LargeView"?6:2} style={{marginBottom:this.mode==="LargeView"?'0.8em':'0'}}>
                <div className={valueChanged?"changed ":""}>
                    {this.mode !== "LargeView" &&
                        <span style={{fontWeight:'bold', display:'block'}}>{shortTitle}</span>
                    }
                    <SingleSelectInputFormField
                        selectedValue={this.state.evaluation[parameterName]}
                        options={allOptions}
                        onChange={(selectionOption) => {
                            let evaluation = JSON.parse(JSON.stringify(this.state.evaluation));
                            if(selectionOption.value == null) {
                                evaluation[parameterName] = null;
                            }else {
                                let value = parseFloat(selectionOption.value);
                                evaluation[parameterName] = value+"";
                            }
                            evaluation = EvaluationFormInput.recalculatePraktischeFetigkeitenSumResult(evaluation);
                            this.setState(prevState => ({
                                ...prevState,
                                evaluation: evaluation
                            }));
                        }}
                    />
                </div>
            </Col>
        </>)
    }


    renderPractivalSkillInputBlock(unterfach, index){
        let self = this;
        let valueChanged = false;
        if(this.state.evaluation.praktische_fertigkeiten.unterfaecher[index].value !== this.state.initialEvaluation.praktische_fertigkeiten.unterfaecher[index].value){
            valueChanged = true;
        }
        let allOptions = JSON.parse(JSON.stringify(EvaluationFormInput.practicalSkillsOptionsShort));
        if(this.mode === "LargeView"){
            allOptions = JSON.parse(JSON.stringify(EvaluationFormInput.practicalSkillsOptions));
        }

        if(this.props.empty){
            if (!allOptions.some(obj => obj.value === null)) {
                allOptions.unshift({name: '≡', value: null})
            }
        }



        return (
            <>
            {this.mode === "LargeView" &&
                    <Col xs={12} md={6} style={{display:'flex',justifyContent:'left',alignItems:'center',marginBottom:'0.2em'}}>
                <span style={{fontWeight:'bold'}}>{unterfach.title}</span>
                </Col>
            }
            <Col xs={this.mode==="LargeView"?12:4}  md={this.mode==="LargeView"?6:3} lg={this.mode==="LargeView"?6:2}>
            <span className={"smallEvaluationTitle"}></span>
                {this.mode !== "LargeView"&&
                    <div className="">
                        <span style={{fontWeight:'bold', display:'block'}}>P{index+1}</span>
                    </div>
                }
            <div className={valueChanged?"changed ":""+unterfach.value==null?"empty":"filled"}  style={{marginBottom:this.mode==="LargeView"?'0.8em':'0'}}>
                {
                    <SingleSelectInputFormField
                        key={"ufach"+index+"_"+unterfach.value}
                        selectedValue={unterfach.value}
                        options={allOptions}
                        onChange={(selectionOption) => {
                            let evaluation = JSON.parse(JSON.stringify(this.state.evaluation));

                            if(selectionOption.value == null) {
                                evaluation.praktische_fertigkeiten.unterfaecher[index].value = null;
                            }else {
                                let value = parseFloat(selectionOption.value);
                                evaluation.praktische_fertigkeiten.unterfaecher[index].value = value.toFixed(1);
                            }

                            let formattedEvaluation = EvaluationFormInput.getFormattedEvaluation(JSON.parse(JSON.stringify(evaluation)));
                            formattedEvaluation = EvaluationFormInput.recalculatePraktischeFetigkeitenSumResult(formattedEvaluation);
                            formattedEvaluation = EvaluationFormInput.getStringFormattedEvaluation(formattedEvaluation);
                            evaluation.praktische_fertigkeiten.points = formattedEvaluation.praktische_fertigkeiten.points;
                            evaluation.praktische_fertigkeiten.grade = formattedEvaluation.praktische_fertigkeiten.grade;

                            this.setState(prevState => ({
                                ...prevState,
                                evaluation: evaluation
                            }));
                        }}
                    />}
            </div>
        </Col>
            </>)
    }


    renderSimpleEvaluationBlock(title, parameterName){
        let selectedValue = this.state.evaluation[parameterName];
        let valueChanged = false;
        if(this.state.initialEvaluation[parameterName] !== this.state.evaluation[parameterName]){
            valueChanged = true;
        }
        //let selectedValue = this.state.evaluations[traineeId][parameterName]!==-1?this.state.evaluations[traineeId][parameterName].toFixed(1):null;
        return (<div className={valueChanged?"changed":""}>
        <span className={"title"}>{title}</span>
            {selectedValue!=="-1"&&selectedValue!==null?<span style={{color:'#222'}} class="badge value bg-info">{selectedValue}</span>:
                <span class="badge value bg-danger">❌</span>}
        </div>)
    }

    renderPracticalSkillsSum(title, parameterName){
        let selectedValue = this.state.evaluation.praktische_fertigkeiten[parameterName];
        let valueChanged = false;
        if(this.state.evaluation.praktische_fertigkeiten[parameterName] !== this.state.evaluation.praktische_fertigkeiten[parameterName]){
            valueChanged = true;
        }
        //let selectedValue = this.state.evaluations[traineeId][parameterName]!==-1?this.state.evaluations[traineeId][parameterName].toFixed(1):null;
        return (<div className={valueChanged?"changed":""}>
            <span className={"title"}>{title}</span>
            {selectedValue!=="-1"&&selectedValue!=="-1.0"&&selectedValue!==null?<span style={{color:'#fff'}} class="badge value bg-black">{selectedValue}</span>:
                <span class="badge value bg-danger">❌</span>}
        </div>)
    }

    renderEvaluationsRow(){
        return (
            <div className={"evaluationMatrix"} style={{paddingLeft:'0.5em'}}>
                <Row>
                    <Col xs={1} md={1} lg={1} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        {this.renderSimpleEvaluationBlock('AV','arbeitsvorbereitung')}
                    </Col>

                    {this.state.evaluation.praktische_fertigkeiten.unterfaecher.map((unterfach,index) => {
                        let valueChanged = false;
                        if(this.state.initialEvaluation.praktische_fertigkeiten.unterfaecher[index].value !== unterfach.value){
                            valueChanged = true;
                        }
                        return <Col xs={1}  md={1} lg={1} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <div className={valueChanged?"changed":""}>
                                <span className={"title"}>P{index+1}</span>
                                {unterfach.value!=="-1.0"&&unterfach.value!==null?<span class="badge value bg-primary">{unterfach.value}</span>:
                                    <span class="badge value bg-danger">❌</span>}
                            </div>
                        </Col>
                    })}
                    <Col xs={1} md={1} lg={1} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        {this.renderPracticalSkillsSum('∑','points')}
                    </Col>
                    <Col xs={1} md={1} lg={1} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        {this.renderPracticalSkillsSum('★','grade')}
                    </Col>
                    <Col xs={1} md={1} lg={1} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        {this.renderSimpleEvaluationBlock('AT','arbeitstempo')}
                    </Col>
                    <Col xs={1} md={1} lg={1} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        {this.renderSimpleEvaluationBlock('AH','arbeitshaltung')}
                    </Col>
                    <Col xs={1}  md={1} lg={1} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        {this.renderSimpleEvaluationBlock('QS','qs_dokumentation')}
                    </Col>

                </Row>
            </div>
        );

    }


    recalcalculateSumView(){
        let formattedEvaluation = EvaluationFormInput.getFormattedEvaluation(JSON.parse(JSON.stringify(this.state.evaluation)));
        formattedEvaluation = EvaluationFormInput.recalculatePraktischeFetigkeitenSumResult(formattedEvaluation);
        formattedEvaluation = EvaluationFormInput.getStringFormattedEvaluation(formattedEvaluation);
        this.state.evaluation.praktische_fertigkeiten.points = formattedEvaluation.praktische_fertigkeiten.points;
        this.state.evaluation.praktische_fertigkeiten.grade = formattedEvaluation.praktische_fertigkeiten.grade;
    }

    render() {
        if(this.state.isFetching || this.state.evaluation == null){
            return (
                <LoadingScreen />
            );
        }
        this.recalcalculateSumView();

        if(this.props.readMode !== undefined){
            return (
                this.renderEvaluationsRow()
            )
        }

        let practicalSkillsSumValid = this.state.evaluation.praktische_fertigkeiten.points!=="-1"
        &&this.state.evaluation.praktische_fertigkeiten.points!=="-1.0"
        &&this.state.evaluation.praktische_fertigkeiten.pointsselectedValue!==null;
            return (
                <>
                    <Row>
                        {this.renderSimpleEvaluationInputBlock('Arbeitsvorbereitung','arbeitsvorbereitung','AV')}


                        {this.state.evaluation.praktische_fertigkeiten.unterfaecher.map((unterfach,index) => {
                            return this.renderPractivalSkillInputBlock(unterfach,index)
                        })}

                        {this.mode==="LargeView" && practicalSkillsSumValid &&
                        <Col xs={12} style={{marginBottom:'1em'}}>
                            <div style={{borderTop:'1px solid #ccc'}}>
                                <Row>
                                    <Col xs={12} md={6} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                        <span>Gesamt</span>
                                    </Col>
                                    <Col xs={12} md={6} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                        {
                                            practicalSkillsSumValid?
                                                <span>{this.state.evaluation.praktische_fertigkeiten.points} Punkte</span>:<span></span>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Col>}

                        {this.renderSimpleEvaluationInputBlock('Arbeitstempo','arbeitstempo','AT')}
                        {this.renderSimpleEvaluationInputBlock('Arbeitshaltung','arbeitshaltung','AH')}
                        {this.renderSimpleEvaluationInputBlock('QS / Dokumentation','qs_dokumentation','QS')}
                        <Col xs={this.mode==="LargeView"?12:12}  md={this.mode==="LargeView"?12:8} lg={this.mode==="LargeView"?12:6} style={{display:'flex',justifyContent:this.mode==="LargeView"?'center':'right',alignItems:'end'}}>
                            <Button style={{marginRight:'1em',marginTop:'1em'}} onClick={this.handleCancelEvaluation}
                                    variant="light"><FontAwesomeIcon icon={faArrowLeft} />
                            </Button>
                            <>
                            {this.mode==="LargeView"?
                                <Button onClick={this.handleSaveEvaluation}
                                        variant="success" className={"btn"}><FontAwesomeIcon icon={faSave} /> Bewertungen speichern
                                </Button>
                            :
                            <Button onClick={this.handleSaveEvaluation}
                                    variant="success">Bestätigen
                            </Button>
                            }
                                </>
                        </Col>
                    </Row>
                </>
            );

    }

}

export default EvaluationFormInput;