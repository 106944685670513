import React, { Component } from 'react';
import {Link} from "react-router-dom";
import {Button, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBackwardStep,
    faCalendar,
    faCube,
    faCubes,
    faProjectDiagram, faUser,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import './TopMenuPageLayout.css';
import logo from "../../assets/logo.PNG";
import UserController from "../../controller/UserController";

class TopMenuPageLayout extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="topMenuPageLayout">
                <div className="page-content">
                    {this.props.children}
                </div>
            </div>
        )
    }
}


export default TopMenuPageLayout;