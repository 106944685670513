import React, {Component} from 'react';
import {Button} from "react-bootstrap";

class ActionButton extends Component {
    render() {
        return (
            <Button
                variant={"dark"}
                onClick={() => {this.props.handler()}}
            >
                {this.props.children}
            </Button>
        );
    }

}
export default ActionButton;