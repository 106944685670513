import React, {Component} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faCommenting} from "@fortawesome/free-solid-svg-icons";

import './ComponentHeader.css';

class ComponentHeader extends Component {
    constructor(props) {
        super(props);
        this.title = props.title;
        this.description = props.description;
        this.hasComments = props.hasComments;
        this.hasEvaluation = props.hasEvaluation;
    }


    render() {
        return(
            <div className={"componentHeader"}>
                <Row>
                    {this.description !== undefined &&
                        <Col lg={9} xs={12}>
                            <span>{this.description}</span>
                        </Col>
                    }
                    <Col lg={(this.description !== undefined)?3:12} xs={12}l>
                        <div className={"componentHeaderIcons"}>
                            {this.hasComments &&
                                <div className={"componentIcon"}>
                                    <FontAwesomeIcon title={"Kommentar Funktion aktiviert"} icon={faCommenting}/>
                                </div>
                            }
                            {this.hasEvaluation &&
                                <div className={"componentIcon"}>
                                    <FontAwesomeIcon title={"Bewertungs-Funktion aktiviert"} icon={faCheckDouble}/>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default ComponentHeader;