import React, {Component} from 'react';
import { Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import './Pageheader.css'
class PageHeader extends Component {
    constructor(props) {
        super(props);
        this.title = props.title;
        this.fontAwesomeIcon = props.fontAwesomeIcon;
    }


    render() {
        return(
            <div className={"pageHeader"}>
                <div className="pageTitle">
                    <Row>
                        {this.title != null &&
                        <Col xs={12} sm={8} lg={6}>
                            <h1><FontAwesomeIcon icon={this.props.fontAwesomeIcon} /> {this.props.title}</h1>
                        </Col>
                        }
                        <Col xs={12} sm={4} lg={6}>
                            {this.props.children}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
export default PageHeader;