import React, { Component } from 'react';
import {Accordion, Card, Col, Row} from "react-bootstrap";
import ActionButton from "./input/ActionButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";

class PradleAccordion extends Component {

    constructor(props) {
        super(props);

        this.keyId = props.keyId;
        this.title = props.title;
        this.open = props.open !== undefined && props.open;
        let openSections = {};
        openSections[this.keyId] = this.open;
        this.state = {
            openSections:openSections
        }
    }

    toggleSection(sectionId){
        if (!this.state.openSections.hasOwnProperty(sectionId)) {
            this.state.openSections[sectionId] = false;
        }
        let openSections = this.state.openSections;
        openSections[sectionId] = !this.state.openSections[sectionId];
        this.setState({
            openSections
        });
    }

    render() {
        return (
            <Accordion className={"pradleAccordion"} activeKey={this.state.openSections[this.keyId]?this.keyId:"-1"}>
                <Card>
                    <Card.Header>
                        <div onClick={(e) => {e.stopPropagation();this.toggleSection(this.keyId)}} className={"cardHeaderWrapper"}>
                            <Row>
                                <Col xs={10}>
                                    <h2 className={this.state.openSections[this.keyId]?"closed":"open"}>
                                        {this.title}
                                    </h2>
                                </Col>
                                <Col xs={2}>
                                    <div align={"right"} style={{paddingRight:'1em',fontSize:'1.5em'}}>
                                        {this.state.openSections[this.keyId]?
                                        <FontAwesomeIcon icon={faCaretUp} />:
                                        <FontAwesomeIcon icon={faCaretDown} />}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={this.keyId}>
                        <Card.Body>
                            {this.props.children}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        )
    }
}


export default PradleAccordion;