import React, { Component } from 'react';
import UserController from "../../controller/UserController";
import './PageLayout.css';
import AdminMenu from "../../components/AdminMenu";
import UserMenu from "../../components/UserMenu";
import APIController from "../../controller/APIController";
import objectUtil from "../../util/objectUtil";
import {Alert} from "react-bootstrap";
const {v4 : uuidv4} = require('uuid')

class PageLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: UserController.getUser(),
            showProfileWarning: false
        }
    }

    async componentDidMount() {
        var self = this;
        if(this.state.user.type === 'trainee'){
            APIController.getTraineeProfile(true).then(async jsonRes => {
                let isFinished = await objectUtil.isTraineeProfileFinished(jsonRes);
                self.setState({
                    showProfileWarning: !isFinished
                })
            });
        }
    }

    render() {
        let uuid = uuidv4();
        return (
            <div className="page">
                {UserController.isAdmin() ?
                    <AdminMenu key={uuid} currentPathName={this.props.location.pathname} />
                    :
                    <UserMenu key={uuid} currentPathName={this.props.location.pathname} />
                }
                {this.state.showProfileWarning &&
                    <div>
                        <Alert variant="danger">
                            Dein <b>Profil ist unvollständig</b>. Bitte hier dein <b><a href={"/trainee/"+this.state.user.id+"/profile/update"} className="link-danger">Persönliches Profil prüfen</a></b>
                        </Alert>
                    </div>
                }
                <div className="content">
                    {this.props.children}
                </div>
                <div className="footer">

                </div>
                <div className="disclaimer">
                    <span>my.ausbildung.cloud</span>
                </div>
            </div>
        )
    }
}


export default PageLayout;