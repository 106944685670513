import React, {Component} from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import './DatatablePageHeader.css'
import {faFilter, faSync} from "@fortawesome/free-solid-svg-icons";
import NumberInputFormField from "../components/formFields/NumberInputFormField";
import PageHeader from "./PageHeader";
import SingleSelectInputFormField from "../components/formFields/SingleSelectInputFormField";

class DataTablePageHeader extends Component {
    constructor(props) {
        super(props);
        this.title = props.title;
        this.fontAwesomeIcon = props.fontAwesomeIcon;
        this.isFetching = props.isFetching;
        this.numTotal = props.numTotal;
        this.pagesTotel = props.pagesTotel;
        this.page = props.page;
        this.onPageChange = props.onPageChange;
        this.onOptionValueSelected = props.onOptionValueSelected;
        this.selectionOptions = props.selectionOptions;
        this.selectedOptionValue = props.selectedOptionValue;
    }


    render() {
        return(
            <PageHeader title={this.title} fontAwesomeIcon={this.fontAwesomeIcon}>
                {!this.isFetching &&
                        <Row>
                            <Col xs={6} lg={5}>
                                <Row>
                                    <Col xs={4} lg={6} style={{display:'flex',justifyContent:'right',alignItems:'center'}}>
                                            <span style={{whiteSpace:'nowrap'}} className={'optionTitle'}>
                                                <FontAwesomeIcon icon={faFilter} />&nbsp;<b>{this.numTotal}</b>
                                            </span>
                                    </Col>
                                    <Col xs={8} lg={6} className={'myselect'} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                        <SingleSelectInputFormField
                                            selectedValue={this.selectedOptionValue}
                                            inputFieldProps={{style:{backgroundColor:'blue',width:'5em',textAlign:'center'}}}
                                            options={this.selectionOptions}
                                            onChange={(selectionOption) => {
                                                this.onOptionValueSelected(selectionOption.value);
                                            } }
                                        />
                                        {this.props.children}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={6} lg={4} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                <div>
                                    <NumberInputFormField
                                        key={this.pagesTotel}
                                        maxValue={this.pagesTotel}
                                        inputFieldProps={{style:{display:'inline',width:'5em',textAlign:'center'}}}
                                        value={this.page}
                                        onChange={(value) => {
                                            this.page = value;
                                            this.onPageChange(this.page);
                                        } }
                                    /> <span className={'paginationNumber'}>/ {this.pagesTotel}</span>
                                </div>
                            </Col>
                        </Row>
                }
            </PageHeader>
        )
    }
}
export default DataTablePageHeader;