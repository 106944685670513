import React, { Component } from 'react';
import {Col, Row} from "react-bootstrap";

import '../components/WorksheetPrintViewComponent.css';
import ComponentBlock from "./ComponentBlock";
import APIController from "../controller/APIController";
import ProjectTaskDescriptionPrintView from "./ProjectTaskDescriptionPrintView";


class WorksheetPrintViewComponent extends Component {

    constructor(props) {
        super(props);
        this.worksheetId = props.worksheetId;
        this.state = {
            isFetching: null,
            worksheet: null,
            message: null
        }
    }

    async reload(){
        try {
            this.setState({
                isFetching:true
            });

            const worksheetId = encodeURIComponent(this.worksheetId);
            const worksheet = await APIController.getPublicWorksheetById(worksheetId);
            console.log(worksheet);

            this.setState({
                isFetching: false,
                worksheet: worksheet,
            });

        } catch (error) {
            this.setState({
                isFetching: false,
                message: error
            });
        }
    }

    componentDidMount() {
        this.reload();
    }


    render() {

        if(this.state.isFetching){
            return (
                <span>lade: {this.worksheetId}</span>
            );
        }else if(this.state.worksheet === null){
            return (
                <div className="worksheetView WorksheetPrintViewComponent">
                    <span>{JSON.stringify(this.state.message)}</span>
                </div>
            );
        }

        return (
            <>
                <div className="worksheetView WorksheetPrintViewComponent">
                        <div style={{padding:"0.5em"}}>
                            <ProjectTaskDescriptionPrintView
                                worksheet={this.state.worksheet}
                                trainee={this.state.worksheet.trainee}
                            />
                        </div>
                    <Row>
                        <Col xs={12} sm={12} lg={12}>
                            <div className={"my-worksheet"}>
                                <div className={"sections"}>
                                    {this.state.worksheet.sections.map((section, sectionIndex) => {
                                        return (!section.hide) &&
                                            <div className={"section-wrapper"}>
                                                <div className={"section-header"}>
                                                    <Row>
                                                        <h2>{section.title}</h2>
                                                    </Row>
                                                </div>
                                                <div className={"section"} key={sectionIndex}>
                                                    <div className={"components"}>
                                                        <Row>
                                                            {section.components.map((component, index) => {
                                                                return (
                                                                    <Col key={index}
                                                                         xs={component.config.size ? component.config.size : 12}>
                                                                        {!component.hide &&
                                                                            <ComponentBlock
                                                                                key={component.id}
                                                                                onRemoveComponentClicked={() => {
                                                                                }}
                                                                                component={component}
                                                                                onComponentUpdate={() => {
                                                                                }}
                                                                                componentIndex={index}
                                                                                readOnlyMode={true}
                                                                                instructorMode={true}
                                                                                showEditorView={false}
                                                                                onCommentsUpdated={() => {
                                                                                }}
                                                                            />
                                                                        }
                                                                    </Col>
                                                                )
                                                            })}
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                        ;
                                    })}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

            </>
        )
    }
}


export default WorksheetPrintViewComponent;