import {useEffect} from "react";
import {useAccount, useIsAuthenticated, useMsal} from "@azure/msal-react";
import LoadingScreen from "../../includes/LoadingScreen";
import UserController from "../../controller/UserController";




function MsalRedirect(props) {
    console.log("MsalRedirect");

    const {instance, accounts, inProgress} = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const account = useAccount(accounts[0] || {});

    const continueLogin = async () => {
        if(accounts && isAuthenticated){
            await instance.acquireTokenSilent({
                scopes: ["User.Read"],
                account: account
            }).then(token => {
                //EVENT EventType.ACQUIRE_TOKEN_SUCCESS handled in index.js with login redirect success
            }).catch(error => {
                console.log(error);
            });
        }
    }


    //const [graphAPIData, setGraphAPIData] = useState(null);

    /*
    const callMsGraph = async (graphEndpoint, accessToken) => {
        console.log('calling graph..');
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        const options = {
            method: "GET", headers: headers
        }
        return fetch(graphEndpoint, options)
            .then(response => {
                response.json();
            })
            .catch(error => {
                console.error(error);
            })
    };

*/
    useEffect(() => {
        continueLogin();
    }, [inProgress, account, instance]);


    return (
        <div>
            <LoadingScreen/>
        </div>
    )
}

export default MsalRedirect;