import React, {Component} from 'react';

import './InstructorBadge.css'
import {Badge} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserTie} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

class InstructorBadge extends Component {
    constructor(props) {
        super(props);
        this.instructor = props.instructor;
    }
    render() {
        return(
            <Link to={"/instructors/"+this.instructor.id}>
                <Badge style={{marginRight:'0.2em',marginBottom:'0.2em'}}
                       className={"instructorBadge"} bg="dark">
                    <FontAwesomeIcon icon={faUserTie}/> {this.instructor.name}
                </Badge>
            </Link>
        )
    }
}
export default InstructorBadge;