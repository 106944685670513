import React, {Component, useId} from 'react';
import {Draggable, Droppable} from "react-beautiful-dnd";
import {
    faCircleArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
const {v4 : uuidv4} = require('uuid')

// fake data generator



const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: "0.5em",
    marginBottom: "0.2em",

    // change background colour if dragging
    background: isDragging ? "#015fa2" : "#f1f1f1",
    color: isDragging ? "#fff" : "#444",
    borderRadius: "5px",
    border: "1px solid #ccc",
    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#1f66a4" : "#1f66a4"
});


class DragableComponentIcons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: this.props.items
        };
        this.unique_id = uuidv4();
    }


    render() {
        return (
            <div>
                <h4><FontAwesomeIcon icon={faCircleArrowLeft} /> {this.props.title}</h4>
                <Droppable type={this.props.type} className={"droppableInputComponent"} droppableId={"dad-components-"+this.unique_id}>
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {this.state.items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            {item.content}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        );
    }
}
export default DragableComponentIcons;