import {Component, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import './PagePopup.css'

    class PagePopup extends Component {

        constructor(props) {
            super(props);
            this.onSave = props.onSave!==undefined?props.onSave:()=>{};
            this.state = {
                show : props.show
            }
            this.className = props.className?props.className:""
            this.handleClose = function (){
                props.onClose();
                this.setState({
                    show:false
                })
            }.bind(this);
            this.handleSave = function (){
                this.onSave();
                this.setState({
                    show:false
                })
            }.bind(this);
            this.handleShow = function (){
                this.setState({
                    show:true
                })
            }.bind(this);
        }

        render(){
            return (
                <>
                    <Modal className={this.className} show={this.state.show} onShow={this.handleShow} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>{this.props.title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{this.props.body}</Modal.Body>
                        {this.props.showFooter &&
                            <Modal.Footer>
                                <Button variant="secondary" onClick={this.handleClose}>
                                    {this.props.closeButtonText!==undefined?this.props.closeButtonText:"Schließen"}
                                </Button>
                                <Button variant="primary" onClick={this.handleSave}>
                                    {this.props.saveButtonText!==undefined?this.props.saveButtonText:"Speichern"}
                                </Button>
                            </Modal.Footer>
                        }
                    </Modal>
                </>
            );
        }

}

export default PagePopup;