import React, {createRef} from 'react';
import {v4 as uuidv4} from "uuid";
import TextComponent from "../input/TextComponent";
import Dropzone from "react-dropzone";
import {Badge, Button, Col, Row} from "react-bootstrap";
import TextInputFormField from "../formFields/TextInputFormField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import NumberInputFormField from "../formFields/NumberInputFormField";
import config from "../../config";
import ModelViewer from "../3DViewer";
import APIController from "../../controller/APIController";
import SimpleGallery from "../SimpleGallery";
import {getImageSize} from "react-image-size";

class ImageContentTextComponent extends TextComponent {
    constructor(props) {
        super(props);
        this.component = props.component;
        this.dropzoneRef = createRef();
        this._handleChange = this._handleChange.bind(this);
        this.updateComponent = props.updateComponent;
        this.onDrop = this.onDrop.bind(this);

        this.state = {
            fetching: true,
            imageFiles : [],
            model3DFiles: [],
            objectFiles: []
        }
    }

    async reload() {
        this.setState({
            fetching: true,
            imageFiles: [],
            model3DFiles: [],
            objectFiles: []
        })
        for (let idx = 0; idx < this.component.config.files.length; idx++) {
            const file = this.component.config.files[idx];
            if (file.type.startsWith("image")) {
                const dimensions = await getImageSize(file.url);
                file.width = dimensions?.width;
                file.height = dimensions?.height;
                file.largeURL = file.url;
                file.thumbnailURL = file.url;
                this.state.imageFiles.push(file);
            }else if(file.type === "application/octet-stream"){
                this.state.model3DFiles.push(file);
            }else{
                this.state.objectFiles.push(file);
            }
        }
        this.setState({
            fetching: false,
            imageFiles: this.state.imageFiles,
            model3DFiles: this.state.model3DFiles,
            objectFiles: this.state.objectFiles
        })
    }

    async componentDidMount() {
       await this.reload();
    }

    openDialog(){
        if (this.dropzoneRef.current) {
            this.dropzoneRef.current.open()
        }
    };

    _handleChange(id,value){
        this.component.config[id] = value;
        this.updateComponent(this.component);
    }

    static create(){
        return {
                id: uuidv4(),
                type : "image",
                description: "",
                title: "Grafik",
                data : {},
                config: {
                    files: [],
                    imageWidth:100
                }
            }
    }

    async onDrop(acceptedFiles){
        var self = this;
        for (const file of acceptedFiles) {
            let maxAllowedSize = this.component.config.maxFilesSize * 1024 * 1024;
            let fileResponse = await APIController.uploadFile(file,maxAllowedSize);
            if(fileResponse != null && fileResponse.url !== ""){
                self.component.config['files'].push(
                    fileResponse
                )
                self.updateComponent(self.component);
            }
        }
    }

    _updateFileName(component,fileId,newFileDescription){
        component.config.files.map( (file, idx) => {
            if(file.id === fileId){
                component.config.files[idx].description = newFileDescription;
            }
        });
    }

    _removeFileById(component,fileId){
        component.config.files.map( (file, idx) => {
            if(file.id === fileId){
                fetch(
                    `${config.API_HOST_URL}/files/${file.name}`,
                    {
                        method: "DELETE",
                    }
                ).then(function(response) {
                    return response.json();
                })
                component.config.files.splice(idx, 1);
            }
        });
    }

    renderEditorView(){
        return (
            <div>
                <Dropzone key={"Dropzone" + Math.random().toString()} onDrop={this.onDrop} ref={this.dropzoneRef} noClick noKeyboard>
                    {({getRootProps, getInputProps, acceptedFiles}) => {

                        return (
                            <div>
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    <p>Drag & Drop Dateien hier</p>
                                    <button
                                        type="button"
                                        onClick={() => this.openDialog()}
                                    >
                                        Datei auswählen
                                    </button>
                                </div>
                                <br />
                                {this.component.config['files'].map(file => (
                                    <Row key={file.id}>
                                        <Col className={'mb-1'} xs={12} lg={5}>
                                            <TextInputFormField
                                                title={""}
                                                rows={1}
                                                placeholder={"Titel/Beschreibung"}
                                                description={""}
                                                onChange={(value) => {
                                                    this._updateFileName(this.component,file.id,value);
                                                    this.updateComponent(this.component);
                                                }}
                                                value={file.description}
                                            />
                                        </Col>
                                        <Col className={'mb-1'} xs={12} lg={2}>
                                            <Button style={{marginRight:'0.5em'}} onClick={() => {
                                                window.location.href = file.url;
                                            }}>
                                                <FontAwesomeIcon icon={faDownload} />
                                            </Button>
                                            <Button onClick={() => {
                                                this._removeFileById(this.component,file.id);
                                                this.updateComponent(this.component);
                                            }}>
                                                <FontAwesomeIcon icon={faTrashCan} />
                                            </Button>
                                        </Col>
                                        <Col className={'mb-1'} xs={12} lg={5}>
                                            {(file.type == "image/png"
                                                || file.type== "image/jpeg"
                                                || file.type== "image/jpg")
                                                ? <img src={file.url} style={{height:'2em'}} />
                                                : (file.type == "application/octet-stream")
                                                    ? <ModelViewer file={file} /> : <Badge bg={"dark"}>{file.extension}</Badge>
                                            }
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        );
                    }}
                </Dropzone>
                <div>
                    <h3>Darstellung</h3>
                    <Row>
                        <Col>
                            <NumberInputFormField
                                title={"Breite in Prozent"}
                                description={"Wieviele Zeilen soll die Tabelle haben?"}
                                maxValue={100}
                                suffix={"%"}
                                steps={10}
                                value={this.component.config.imageWidth}
                                onChange={(value) => this._handleChange('imageWidth',value)}
                            />
                        </Col>
                        <Col>

                        </Col>
                    </Row>
                </div>
            </div>
        );
    }





    renderInputView(){
        var imageWidthInProcent = "calc("+this.component.config.imageWidth+'% - 1em)';
        return (
            <div className={this.component.type}>

                <SimpleGallery
                    key={"gal_"+this.component.id}
                    galleryID="my-test-gallery"
                    images={this.state.imageFiles}
                />

                {this.state.model3DFiles.map(file => (
                    <div key={'inputView' + Math.random().toString()} style={{width:imageWidthInProcent,float:'left',marginRight:'1em',marginBottom:'1em'}}>
                        <div>
                            <ModelViewer file={file}/>
                            <span>{file.description}</span>
                        </div>
                    </div>
                ))}
                {this.state.objectFiles.map(file => (
                    <div key={'inputView' + Math.random().toString()} style={{width:imageWidthInProcent,float:'left',marginRight:'1em',marginBottom:'1em'}}>
                        <div>
                            <a href={file.url}>Download</a>
                            <span>{file.description}</span>
                        </div>
                    </div>
                ))}
                <div style={{clear:"both"}}></div>
            </div>
        );
    }

    render() {

        if(this.state.fetching){
            return "Loading ...";
        }

        if(this.props.instructorMode){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderInputView()}
            </div>
        } else if(this.props.showEditorView){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderEditorView()}
            </div>
        }else{
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderInputView()}
            </div>
        }
    }
}
export default ImageContentTextComponent;