import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCircleInfo,
    faDownload, faExternalLink,
    faFile,
    faFileText,
    faHeading,
    faImage,
    faList,  faPlug,
    faTable,
    faWarning
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import TableInput from "./input/TableInput";
import TextareaInput from "./input/TextareaInput";
import MarkdownTextComponent from "./input/MarkdownTextComponent";
import MultipleChoiceInput from "./input/MultipleChoiceInput";
import FileUploadInput from "./input/FileUploadInput";
import GefaerdungsbeurteilungInput from "./input/GefaerdungsbeurteilungInput";
import HeadlineTextComponent from "./text/HeadlineTextComponent";
import ContentTextComponent from "./text/ContentTextComponent";
import ImageTextComponent from "./text/ImageTextComponent";
import DownloadTextComponent from "./text/DownloadTextComponent";
import ExternalLinkTextComponent from "./text/ExternalLinkTextComponent";

class ComponentUtil {

    static createNewComponentByTypeId(typeId){
        switch (typeId){
            case "tableInput":
                return TableInput.create();
            case "textInput":
                return TextareaInput.create();
            case "markdownText":
                return MarkdownTextComponent.create();
            case "multipleChoice":
                return MultipleChoiceInput.create();
            case "fileUpload":
                return FileUploadInput.create();
            case "gefaehrdungsbeurteilung":
                return GefaerdungsbeurteilungInput.create();
            case "headline":
                return HeadlineTextComponent.create();
            case "text":
                return ContentTextComponent.create();
            case "image":
                return ImageTextComponent.create();
            case "download":
                return DownloadTextComponent.create();
            case "externalLink":
                return ExternalLinkTextComponent.create();
            default:
                throw new Error("invalid component type: "+typeId);
        }
    }

    static getTitleByComponentType(componentType) {

        switch (componentType) {
            case 'textInput':
                return 'Text-Eingabe';
            case 'markdownText':
                return 'Markdown-Text';
            case 'fileUpload':
                return 'Datei-Upload';
            case 'multipleChoice':
                return 'Multiple-Choice';
            case 'tableInput':
                return 'Tabellen-Eingabe';
            case 'gefaehrdungsbeurteilung':
                return 'Gefährdungsbeurteilung';
            case 'headline':
                return 'Headline';
            case 'text':
                return 'Text';
            case 'image':
                return 'Grafik';
            case 'download':
                return 'Datei-Download';
            case 'externalLink':
                return 'Externer-Link';
            case 'ltb':
                return 'Learning-Toolbox';
            case 'docuWare':
                return 'DocuWare';
            default:
                throw new Error("Unknown componentType: "+componentType);
        }
    }

    static getIconByComponentType(componentType) {

        switch (componentType) {
            case 'textInput':
                return <FontAwesomeIcon icon={faFileText}/>;
            case 'markdownText':
                return <FontAwesomeIcon icon={faCircleInfo}/>;
            case 'fileUpload':
                return <FontAwesomeIcon icon={faFile}/>;
            case 'multipleChoice':
                return <FontAwesomeIcon icon={faList}/>;
            case 'tableInput':
                return <FontAwesomeIcon icon={faTable}/>;
            case 'gefaehrdungsbeurteilung':
                return <FontAwesomeIcon icon={faWarning}/>;
            case 'headline':
                return <FontAwesomeIcon icon={faHeading}/>;
            case 'text':
                return <FontAwesomeIcon icon={faFileText}/>;
            case 'image':
                return <FontAwesomeIcon icon={faImage}/>;
            case 'download':
                return <FontAwesomeIcon icon={faDownload}/>;
            case 'externalLink':
                return <FontAwesomeIcon icon={faExternalLink}/>;
            case 'ltb':
                return <FontAwesomeIcon icon={faPlug}/>;
            case 'docuWare':
                return <FontAwesomeIcon icon={faPlug}/>;
            default:
                throw new Error("Unknown componentType: "+componentType);
        }
    }

    static _getComponentById(componentTypeId){
        return {
            id: componentTypeId,
            content: <span key={componentTypeId}>{ComponentUtil.getIconByComponentType(componentTypeId)} {ComponentUtil.getTitleByComponentType(componentTypeId)}</span>
        }
    }

    static getEingabeKomponenten() {
        return [
            ComponentUtil._getComponentById('textInput'),
            ComponentUtil._getComponentById('fileUpload'),
            ComponentUtil._getComponentById('multipleChoice'),
            ComponentUtil._getComponentById('tableInput'),
            ComponentUtil._getComponentById('gefaehrdungsbeurteilung')
        ];
    }
    static getTextKomponenten() {
        return [
            ComponentUtil._getComponentById('markdownText'),
            ComponentUtil._getComponentById('headline'),
            ComponentUtil._getComponentById('image'),
            ComponentUtil._getComponentById('download'),
            ComponentUtil._getComponentById('externalLink')
        ];
    }

    static getSpecialKomponenten() {
        return [
            ComponentUtil._getComponentById('ltb'),
            ComponentUtil._getComponentById('docuWare'),
        ];
    }

}
export default ComponentUtil;