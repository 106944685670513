import React, {Component} from 'react';

import './TraineeBadge.css'
import {Badge} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import he from "he";

class TraineeBadge extends Component {
    constructor(props) {
        super(props);
        this.trainee = props.trainee;
    }
    render() {
        return(
            <Link to={"/trainee/"+this.trainee._id+"/profile"}>
                <Badge bg={"dark"} style={{marginRight:'0.2em',marginBottom:'0.2em'}}
                       className={"traineeBadge"}>
                    <FontAwesomeIcon icon={faUser}/> {he.decode(this.trainee.name+", "+this.trainee.firstname)}
                </Badge>
            </Link>
        )
    }
}
export default TraineeBadge;