import React, {Component} from 'react';

import { Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faComments,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import APIController from "../../controller/APIController";
import PhaseSelectionBox from "../PhaseSelectionBox";
import StatusBadge from "../badges/StatusBadge";

class SmartWorksheetActionButtons extends Component {
    constructor(props) {
        super(props);
        this.worksheet = props.worksheet;
        this.traineeId = props.traineeId;
        this.userType = props.userType;
        this.onDeleteWorkingSheet = props.onDeleteWorkingSheet;
        this.onWorksheetUpdated = props.onWorksheetUpdated;
        this.evaluation = props.evaluation?props.evaluation:undefined;
        this.onEvaluationUpdated = props.onEvaluationUpdated?props.onEvaluationUpdated:()=>{};
    }

    calculateButtonVisibilityByState(worksheetState){

        let showPhaseSelectionBox = false;
        let showEditButton = false;
        let showCommentButton = false;
        let showFinalDeleteButton = false;

        if(this.userType === 'trainee'){
            switch (worksheetState) {
                case APIController.WORKSHEET_STATES.DISCARDED_WORKING_SHEET:
                    showPhaseSelectionBox = false;
                    showEditButton = false;
                    showCommentButton = false;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.GRADED_WORKING_SHEET:
                    showPhaseSelectionBox = true;
                    showEditButton = false;
                    showCommentButton = false;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.OPEN_WORKING_SHEET:
                    showPhaseSelectionBox = false;
                    showEditButton = true;
                    showCommentButton = false;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.SUBMITTED_WORKING_SHEET:
                    showPhaseSelectionBox = true;
                    showEditButton = false;
                    showCommentButton = false;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.DRAFT_WORKING_SHEET:
                    showPhaseSelectionBox = false;
                    showEditButton = false;
                    showCommentButton = false;
                    showFinalDeleteButton = false;
                    break;
            }
        }else{
            switch (worksheetState) {
                case APIController.WORKSHEET_STATES.DISCARDED_WORKING_SHEET:
                    showPhaseSelectionBox = false;
                    showEditButton = true;
                    showCommentButton = true;
                    showFinalDeleteButton = true;
                    break;
                case APIController.WORKSHEET_STATES.GRADED_WORKING_SHEET:
                    showPhaseSelectionBox = false;
                    showEditButton = true;
                    showCommentButton = true;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.OPEN_WORKING_SHEET:
                    showPhaseSelectionBox = true;
                    showEditButton = true;
                    showCommentButton = true;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.SUBMITTED_WORKING_SHEET:
                    showPhaseSelectionBox = false;
                    showEditButton = true;
                    showCommentButton = true;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.DRAFT_WORKING_SHEET:
                    showPhaseSelectionBox = false;
                    showEditButton = true;
                    showCommentButton = true;
                    showFinalDeleteButton = true;
                    break;
            }
        }

        return {
            showPhaseSelectionBox : showPhaseSelectionBox,
            showEditButton : showEditButton,
            showCommentButton :showCommentButton,
            showFinalDeleteButton:showFinalDeleteButton
        }
    }



    static getURLByState(state, worksheetId, traineeId) {
        switch (state) {
            case APIController.WORKSHEET_STATES.DISCARDED_WORKING_SHEET:
                return "/worksheets/" + worksheetId + "?readOnly=true";
            case APIController.WORKSHEET_STATES.GRADED_WORKING_SHEET:
                return "/worksheets/" + worksheetId + "?readOnly=true";
            case APIController.WORKSHEET_STATES.OPEN_WORKING_SHEET:
                return "/worksheets/" + worksheetId;
            case APIController.WORKSHEET_STATES.SUBMITTED_WORKING_SHEET:
                return "/worksheets/" + worksheetId + "?readOnly=true";
            case APIController.WORKSHEET_STATES.DRAFT_WORKING_SHEET:
                return "/worksheets/" + worksheetId + "?readOnly=true";
            default:
                return "/worksheets/" + worksheetId + "?readOnly=true";
        }
    }


    render() {
        var self = this;
        var buttonVis = this.calculateButtonVisibilityByState(this.worksheet.state);
        return (
            <div>
                {buttonVis.showPhaseSelectionBox &&
                    <>
                        <div style={{display:"inline-block",marginRight:'0.4em'}}>
                            <PhaseSelectionBox onWorksheetUpdated={self.onWorksheetUpdated} worksheet={this.worksheet}/>
                        </div>
                    </>
                }
                    {buttonVis.showCommentButton &&
                        <Link to={"/worksheets/"+ this.worksheet.id}>
                            <Button title={"Bewerten"}
                                    variant={'info'} style={{marginRight:'0.2em'}}>
                                <FontAwesomeIcon icon={faComments} /></Button>
                        </Link>
                    }
                    {buttonVis.showFinalDeleteButton &&
                        <Button title={"Endgültig Löschen"} onClick={()=>{self.onDeleteWorkingSheet(this.worksheet)}}
                                variant={'danger'} style={{marginRight:'0.2em'}}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    }
                {buttonVis.showEditButton &&
                    <StatusBadge onEvaluationUpdated={self.onEvaluationUpdated} changeable={(this.userType === 'instructor')} smallSize={true} onWorksheetUpdated={self.onWorksheetUpdated} worksheet={this.worksheet}/>
                }
                </div>
        )
    }
}
export default SmartWorksheetActionButtons;