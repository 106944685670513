import { useEffect, useRef, useState } from "react";
import "./3DViewer.css";
import LoadingScreen from "../includes/LoadingScreen";
import config from "../config";

const ModelViewer = ({file, props}) => {
    console.log(file);

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null)
    const modelRef = useRef();
    const [annots, setAnnots] = useState([]);

    useEffect( () => {
        // download files as
        setData(`${config.API_HOST_URL}/files/${file.name}`);
        setIsLoading(false);
    }, [file])


    const handleClick = (event) => {
        const { clientX, clientY } = event;

        if (modelRef.current) {
            let hit = modelRef.current.positionAndNormalFromPoint(clientX, clientY);
            if (hit) {
                setAnnots((annots) => {
                    return [...annots, hit];
                });
            }
        }
    };

    const getDataPosition = (annot) => {
        return `${annot.position.x} ${annot.position.y} ${annot.position.z}`;
    };

    const getDataNormal = (annot) => {
        return `${annot.normal.x} ${annot.normal.y} ${annot.normal.z}`;
    };

    return (
        <>
            {isLoading ? (<LoadingScreen/>)
                :
                <model-viewer

                    src={data}
                    alt={file.description}
                    ref={(ref) => {
                        modelRef.current = ref;
                    }}
                    onClick={handleClick}
                    ar
                    ar-scale="fixed"
                    camera-controls
                    touch-action="pan-y"
                    shadow-intensity="2"
                    tone-mapping="neutral"
                    loading={'eager'}
                    skybox-height="2m"
                    max-camera-orbit="auto 90deg auto"
                    xr-environment
                >
                    {annots.map((annot, idx) => (
                        <span
                            key={`hotspot-${idx}`}
                            className="button-span"
                            slot={`hotspot-${idx}`}
                            data-position={getDataPosition(annot)}
                            data-normal={getDataNormal(annot)}
                        ></span>
                    ))}
                </model-viewer>
            }
        </>
    );
};

export default ModelViewer;
