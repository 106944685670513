import React, {Component} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
class TextInputFormField extends Component {
    constructor(props) {
        super(props);
        this.id = props.id;
        this.title = props.title;
        this.rows = props.rows;
        this.placeholder = props.placeholder;
        this.description = props.description;
        this.state = {
            value: props.value
        };
        this.textInput = React.createRef();
        this.disabled = props.disabled ? props.disabled: false;
        this.onChange = props.onChange;
        this._handleChange = this._handleChange.bind(this);
    }

    _handleChange(){
        this.setState({ value:this.textInput.current.value });
        this.onChange(this.textInput.current.value);
    }

    getValue(){
        return this.state.value;
    }

    setValue(value){
        this.state.value = value;
    }

    render() {

        if(this.rows > 1){
            return(
                <Form.Group>
                    {this.title && <Form.Label>{this.title}</Form.Label>}
                    <Form.Control rows={this.rows} value={this.state.value} ref={this.textInput} onBlur={(e) => {
                        if(e.nativeEvent.type === 'focusout' && e.target.value === '') {}
                    }} disabled={this.disabled}
                                  onChange={this._handleChange} as="textarea"  type="text" placeholder={this.placeholder} />
                    {this.description &&
                        <Form.Text>
                            {this.description}
                        </Form.Text>
                    }
                </Form.Group>
            )
        }else{
            return(
                <Form.Group>
                    {this.title && <Form.Label>{this.title}</Form.Label>}
                    <Form.Control value={this.state.value} ref={this.textInput} onBlur={(e) => {
                        if(e.nativeEvent.type === 'focusout' && e.target.value === '') {console.log("empty"); this._handleChange()}
                    }} disabled={this.disabled}
                                  onChange={this._handleChange} type="text" placeholder={this.placeholder} />
                    {this.description &&
                        <Form.Text>
                            {this.description}
                        </Form.Text>
                    }
                </Form.Group>
            )
        }


    }
}
export default TextInputFormField;