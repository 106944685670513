import React, { Component } from 'react';
import {Accordion, Alert, Badge, Button, Card, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Toast from '@radix-ui/react-toast';
import {
    faArrowLeft, faArrowRight,
    faBackward, faBackwardStep,
    faCancel,

    faCaretDown, faCaretUp, faCheck, faCheckSquare, faClock, faClockFour, faClockRotateLeft, faClose,
    faDiagramProject, faEdit, faFileText, faSave, faSquare, faStop, faUser, faUserClock, faUsers,
} from "@fortawesome/free-solid-svg-icons";
import '../components/WorksheetTableAccordion.css';
import {Link} from "react-router-dom";
import TraineeBadge from "./badges/TraineeBadge";
import DateTimeUtil from "../util/DateTimeUtil";
import SmartWorksheetActionButtons from "./WorksheetEditorComponents/SmartWorksheetActionButtons";
import UserController from "../controller/UserController";
import APIController from "../controller/APIController";
import Progress from "./progressBar";
import WorksheetListStateBadges from "./badges/WorksheetListStateBadges";
import LoadingScreen from "../includes/LoadingScreen";
import EvaluationFormInput from "./WorksheetEditorComponents/EvaluationFormInput";
import he from "he";
import '../../src/App.css';



class WorksheetTableAccordion extends Component {

    constructor(props) {
        super(props);
        this.onWorksheetUpdated = this.onWorksheetUpdated.bind(this);
        this.onEvaluationUpdated = this.onEvaluationUpdated.bind(this);
        this.handleDeleteWorksheet = this.handleDeleteWorksheet.bind(this);
        this.onWorksheetsUpdated = props.onWorksheetsUpdated;
        this.userType = props.userType;
        this.params = props.params;
        this.allTopics = props.allTopics;
        this.topicId = props.topicId?props.topicId:null;
        this.courseId = props.courseId?props.courseId:this.props.worksheets[0].courseId;
        this.groupedByTopics = props.groupedByTopics?props.groupedByTopics:false;

        this.state = {
            isFetching: true,
            showEvaluations: false,
            updatedEvaluations: {},
            evaluations: {},
            editeGradesRowMap: {},
            showMultiEvaluationForm:false,
            selectedTraineesMap: {},
            open:false
        };
    }

    async componentDidMount() {
        try {
            let filteredWorksheets = this.props.worksheets;
            if(this.topicId != null){
                filteredWorksheets = this.props.worksheets.filter(obj => obj.topic === this.topicId);
            }
            let openSections = {};
            if(localStorage.getItem('WorksheetTableAccordion_openSections') != null){
                openSections = JSON.parse(localStorage.getItem('WorksheetTableAccordion_openSections'));
            }
            localStorage.setItem('WorksheetTableAccordion_openSections',JSON.stringify(openSections));
            let evaluations = await APIController.getEvaluationsByCourseAndTopic(this.courseId, this.topicId);

            const initialEvaluations = JSON.parse(JSON.stringify(evaluations));

            this.setState(prevState => ({
                ...prevState,
                isFetching: false,
                worksheets: filteredWorksheets,
                openSections: openSections,
                evaluations: evaluations,
                initialEvaluations: initialEvaluations
            }));
        } catch (e) {
            throw e;
        }
    }

    async onWorksheetUpdated(updatedWorksheet) {
        let self = this;

        let ws = this.state.worksheets.filter(obj => obj.id === updatedWorksheet.id)[0];
        if (updatedWorksheet.evaluation !== undefined) {
            ws.evaluation = updatedWorksheet.evaluation;
        }
        if (updatedWorksheet.phase !== undefined) {
            ws.phase = updatedWorksheet.phase;
        }
        let wsUpdated = await APIController.updateWorksheetV2(updatedWorksheet);
        let evaluations = await APIController.getEvaluationsByCourseAndTopic(ws.courseId, ws.topic);
        const initialEvaluations = JSON.parse(JSON.stringify(evaluations));

        const index = self.state.worksheets.findIndex(wsobj => wsobj.id + "" === updatedWorksheet.id + "");
        self.state.worksheets[index] = updatedWorksheet;
        this.setState(prevState => ({
            ...prevState,
            isFetching: false,
            worksheets: this.state.worksheets,
            evaluations: evaluations,
            initialEvaluations: initialEvaluations
        }));
    }

    onEvaluationUpdated(traineeId, updatedEvaluation){
        let evaluations = this.state.evaluations;
        evaluations[traineeId] = updatedEvaluation;
        this.setState(prevState => ({
            ...prevState,
            evaluations: evaluations
        }));
    }

    handleDeleteWorksheet(workingsheet){
        let self = this;
        let ws = this.state.worksheets.filter(obj => obj.id === workingsheet.id)[0];

        APIController.deleteWorksheet(
            ws.id,
            true,
            (json) => {
                console.log("worksheet deleted...");
                const index = self.state.worksheets.findIndex(wsobj => wsobj.id+"" === workingsheet.id+"");

                self.state.worksheets.splice(index, 1);
                self.setState(prevState => ({
                    ...prevState,
                    worksheets: this.state.worksheets
                }));
                self.onWorksheetsUpdated(this.state.worksheets);




            },
            (err) => {
                console.log(`Error: ${err}`)
            });
    }


    toggleSection(sectionId){
        if (!this.state.openSections.hasOwnProperty(sectionId)) {
            this.state.openSections[sectionId] = false;
        }
        let openSections = this.state.openSections;
        openSections[sectionId] = !this.state.openSections[sectionId];
        this.setState({
            openSections
        });
        localStorage.setItem('WorksheetTableAccordion_openSections',JSON.stringify(openSections));
    }


    renderTable(topicId){
        let self = this;

        if(this.state.worksheets.length === 0){
            return <><h3>Keine Arbeitsblätter</h3></>
        }

        if(this.state.showMultiEvaluationForm){
            return <div style={{marginBottom:'5em'}}>
                <>

                    <h4 style={{marginBottom:'0.5em'}}>
                        <Button style={{marginRight:'1em',marginTop:'0em'}} onClick={() => {
                            this.setState(prevState => ({
                                ...prevState,
                                showMultiEvaluationForm: false
                            }));
                        }}
                                variant="light"><FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                        <FontAwesomeIcon icon={faUsers} /> Gruppenbewertung</h4>
                    <span style={{display:'block',marginBottom:'1em'}}>Sie können mehrere Azubis auswählen um die Noten gruppenweise zu vergeben. Das Symbol (<b>≡</b>) bedeutet, dass die Note unverändert bleiben soll.</span>
                    <Row>
                        <Col xs={12} md={4}>

                                {this.state.worksheets.map((w) => { return (<div>{this.renderTraineeSelectionRow(w.trainee)}</div>)})}
                        </Col>
                        <Col xs={12} md={8}>
                            {Object.keys(this.state.selectedTraineesMap) !== undefined && Object.keys(this.state.selectedTraineesMap).length > 0 &&
                                <h5>{Object.keys(this.state.selectedTraineesMap).length} ausgewählt</h5>
                            }
                            <div className={'multiEvaluationBody '}>
                            <EvaluationFormInput
                                empty={true}
                                mode={"LargeView"}
                                evaluation={this.state.evaluations[this.state.worksheets[0].traineeId]}
                                initialEvaluation={this.state.evaluations[this.state.worksheets[0].traineeId]}
                                onFinishEvaluation={async (updatedEvaluation) => {

                                    let newEvaluations = JSON.parse(JSON.stringify(this.state.evaluations));
                                    Object.keys(this.state.selectedTraineesMap).forEach(traineeId => {


                                        let newTraineeEvaluation = JSON.parse(JSON.stringify(this.state.evaluations[traineeId]));

                                        updatedEvaluation.praktische_fertigkeiten.unterfaecher.map((unterfach,index) => {
                                            if(unterfach.value !== null){
                                                newTraineeEvaluation.praktische_fertigkeiten.unterfaecher[index].value = parseFloat(unterfach.value);
                                            }
                                        });
                                        EvaluationFormInput.evaluationKeys.map((keyName,index) => {
                                            if(updatedEvaluation[keyName] !== null && updatedEvaluation[keyName] !== "null"){
                                                newTraineeEvaluation[keyName] = parseInt(updatedEvaluation[keyName]);
                                            }
                                        });

                                        newEvaluations[traineeId] = newTraineeEvaluation;

                                    });

                                    this.setState(prevState => ({
                                        ...prevState,
                                        showMultiEvaluationForm: false,
                                        selectedTraineesMap:{},
                                        evaluations: newEvaluations
                                    }));
                                }}/>
                            </div>
                        </Col>
                    </Row>
                </>
            </div>
        }else{
            return <div style={{marginBottom:'5em'}}>
                {this.renderPracticalSkillsLegend()}
                {this.state.worksheets.map((w) => {
                    return (
                        this.renderTraineeWorksheetRow(w)
                    )
                })
                }
                {this.renderUpdateChangesButton()}
            </div>
        }

    }

    renderTraineeSelectionRow(trainee){
        return (
            <div key={"traineeSelectionRow_"+trainee._id} style={{display:"inline-block",marginBottom:'0.5em',marginRight:'0.5em'}}>
                {this.state.selectedTraineesMap[trainee._id] === undefined ?
                    <Button variant={"light"}  onClick={() => {
                        let selectedTraineesMap = this.state.selectedTraineesMap;
                        selectedTraineesMap[trainee._id] = true;
                        this.setState(prevState => ({
                            ...prevState,
                            selectedTraineesMap: selectedTraineesMap
                        }));
                    }}>
                        <FontAwesomeIcon icon={faSquare}/> {he.decode(trainee.name+", "+trainee.firstname)}
                    </Button>: <Button variant={"dark"} onClick={() => {
                        let selectedTraineesMap = this.state.selectedTraineesMap;
                        delete selectedTraineesMap[trainee._id];
                        this.setState(prevState => ({
                            ...prevState,
                            selectedTraineesMap: selectedTraineesMap
                        }));
                    }}>
                        <FontAwesomeIcon icon={faCheckSquare}/> {he.decode(trainee.name+", "+trainee.firstname)}
                    </Button>
                }


            </div>
        )
    }

    renderTraineeWorksheetRow(worksheet){
        return (
            <div key={worksheet.id}>
                <Row className={`worksheetListRow ${worksheet.state}`}>
                    <Col xs={12}  lg={5} style={{display:'flex',justifyContent:'left',alignItems:'start', marginBottom:'1em'}}>

                        <TraineeBadge trainee={worksheet.trainee}></TraineeBadge>
                        <Badge style={{marginLeft:'1em'}} bg={"light"}>
                            <div style={{display:"inline-block"}}>
                                <span style={{marginRight:'0.2em',whiteSpace:'nowrap',fontWeight:'bold'}} className={"editedDate"}><FontAwesomeIcon style={{marginLeft:'0.2em'}} icon={faClockRotateLeft}/> {DateTimeUtil.getHumanReadableDate(worksheet.lastEditedTime,false)},</span>
                                <span style={{whiteSpace:'nowrap'}} className={"editedTime"}>{DateTimeUtil.getHumanReadableTime(worksheet.lastEditedTime)}</span>
                            </div>
                        </Badge>

                    </Col>
                    {this.state.editeGradesRowMap[worksheet.traineeId] === undefined &&

                        <Col xs={12} sm={12} lg={7} style={{display:'flex',justifyContent:'right',alignItems:'center', marginBottom:'0.2em'}}>
                            <SmartWorksheetActionButtons
                                key={worksheet.lastEditedTime}
                                worksheet={worksheet}
                                evaluation={this.state.evaluations[worksheet.traineeId]}
                                onDeleteWorkingSheet={this.handleDeleteWorksheet}
                                onEvaluationUpdated={this.onEvaluationUpdated}
                                traineeId={worksheet.traineeId}
                                userType={UserController.getUser().type}
                                onWorksheetUpdated={this.onWorksheetUpdated}
                            />


                    </Col>
                    }

                    <Col xs={12} sm={12} lg={this.state.editeGradesRowMap[worksheet.traineeId] === undefined?8:12} style={{justifyContent:'right',alignItems:'center', marginBottom:'1em'}}>
                        {this.state.editeGradesRowMap[worksheet.traineeId] !== undefined &&
                            <>{this.renderEvaluationsWriteRow(worksheet.traineeId)}</>
                        }
                        {this.state.editeGradesRowMap[worksheet.traineeId] === undefined &&
                            <>{this.renderEvaluationsRow(worksheet.traineeId)}</>
                        }
                    </Col>
                    {this.state.editeGradesRowMap[worksheet.traineeId] === undefined &&

                        <Col xs={12}  lg={4} style={{display:'flex',justifyContent:'right',alignItems:'center', marginBottom:'0.2em'}}>
                            <Button style={{marginLeft:'1em'}} title={"Ändern"} onClick={()=>{
                                this.state.editeGradesRowMap[worksheet.traineeId] = true;
                                this.setState(this.state);
                            }}
                                    variant={'light'}>
                                <FontAwesomeIcon icon={faEdit} /> Bewertung
                            </Button>

                        </Col>
                    }
                </Row>
                <div>


                </div>
            </div>
        )
    }


    async saveEvaluations(){
        this.setState(prevState => ({
            ...prevState,
            isFetching: true,
        }));
        let evaluationsToSave = {};

        Object.keys(this.state.evaluations).forEach(key => {
            let traineeId = key;
            if (this.state.evaluations.hasOwnProperty(traineeId)) { // Überprüft, ob die Eigenschaft direkt auf dem Objekt ist (ohne Prototyp)
                if(JSON.stringify(this.state.evaluations[traineeId]) !== JSON.stringify(this.state.initialEvaluations[traineeId])){
                    let evaluation = JSON.parse(JSON.stringify(EvaluationFormInput.getFormattedEvaluation(this.state.evaluations[traineeId])));
                    evaluation = EvaluationFormInput.recalculatePraktischeFetigkeitenSumResult(evaluation);
                    evaluationsToSave[traineeId] = evaluation;
                }
            }
        });
        let evaluations = await APIController.updateEvaluationsByCourseAndTopic(this.courseId, this.topicId, evaluationsToSave);
        let initialEvaluations = JSON.parse(JSON.stringify(this.state.evaluations));
        let course = await APIController.getCourseById(this.courseId);
        let worksheetsFilteredByTopic = course.worksheets.filter(ws => ws.topic === this.topicId);

        this.setState(prevState => ({
            ...prevState,
            isFetching: false,
            initialEvaluations: initialEvaluations,
            editeGradesRowMap: {},
            open:true,
            worksheets: worksheetsFilteredByTopic
        }));
    }

    renderUpdateChangesButton(){
        let changed = false;
        if(JSON.stringify(this.state.evaluations) !== JSON.stringify(this.state.initialEvaluations)){
            changed = true;
        }
        if(changed){
            return(
                <div style={{position:"fixed",textAlign:'center',bottom:'0em',borderTop:'2px solid #fff',
                    padding:'1em',left:0,zIndex:2,width:'100%',height:'auto',background:'#11578f'}}>
                    <div>
                        <Button variant="light" className={"btn btn-lg"} onClick={async () => {
                            await this.saveEvaluations();
                        }}>
                            <FontAwesomeIcon icon={faArrowRight} /> Bewertungen speichern
                        </Button>
                    </div>
                </div>
            )
        }else{
            return <></>
        }
    }

    

    renderPracticalSkillsLegend(){
        let traineeIds = Object.keys(this.state.evaluations);
        if(traineeIds.length == 0){
            return (<></>);
        }else{
            return (
                <>
                    <Row>
                        <Col xs={12} md={4} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                            {this.state.showMultiEvaluationForm ?
                            <Button title={"Ändern"} onClick={()=>{
                                this.setState(prevState => ({
                                    ...prevState,
                                    showMultiEvaluationForm: false
                                }));
                            }} variant={'light'}>
                                <FontAwesomeIcon icon={faArrowLeft} /> zurück
                            </Button>:<Button title={"Ändern"} onClick={()=>{
                                    this.setState(prevState => ({
                                        ...prevState,
                                        showMultiEvaluationForm: true
                                    }));
                                }} variant={'light'}>
                                    <FontAwesomeIcon icon={faUsers} /> Gruppenbewertung
                                </Button>
                            }
                        </Col>
                        <Col xs={12} md={8} style={{display:'flex',justifyContent:'right',alignItems:'end'}}>
                            <div style={{paddingTop:'1em',paddingBottom:'1em'}}>
                                {this.state.evaluations[traineeIds[0]].praktische_fertigkeiten.unterfaecher.map((unterfach,index) => {
                                    return (
                                        <div style={{marginLeft:'1em',display:"inline-block"}}><span style={{fontWeight:"bold"}}>P{index+1}</span> <Badge bg={"dark"}>{this.state.evaluations[traineeIds[0]].praktische_fertigkeiten.unterfaecher[index].title}</Badge></div>
                                    )
                                })}
                            </div>
                        </Col>
                    </Row>


                </>

            );
        }
    }

    renderEvaluationsWriteRow(traineeId){
        return(
            <>
            <EvaluationFormInput
                mode={"shortView"}
                evaluation={this.state.evaluations[traineeId]}
                initialEvaluation={this.state.initialEvaluations[traineeId]}
                onFinishEvaluation={async (updatedEvaluation) => {
                    this.state.evaluations[traineeId] = updatedEvaluation;
                    delete this.state.editeGradesRowMap[traineeId];
                    this.setState(this.state);
                }}/>
            </>
        );
    }

    renderEvaluationsRow(traineeId){
        if(this.state.evaluations[traineeId] === undefined){
            return(
                <Alert variant="danger">
                    Keine Bewertung für Azubi: {traineeId} verfügbar
                </Alert>
            )
        }
        return(
            <div>
                <Row>
                    <Col xs={12} style={{display:'flex',justifyContent:'left',alignItems:'start'}}>
                        <EvaluationFormInput
                            key={traineeId+JSON.stringify(this.state.evaluations[traineeId])}
                            evaluation={this.state.evaluations[traineeId]}
                            initialEvaluation={this.state.initialEvaluations[traineeId]}
                            onFinishEvaluation={async (updatedEvaluation) => {

                            }}
                            readMode={true}
                        />
                    </Col>
                </Row>
            </div>

        );
    }

    renderAccordionWithTable(id,topicId){
        return (
            <>
                <Accordion className={"pradleAccordion"} activeKey={this.state.openSections[id]?id:"-1"}>
                    <Card>
                        <Card.Header>
                            <div className={"cardHeaderWrapper"} onClick={(e) => {e.stopPropagation();this.toggleSection(id)}}>
                                <Row>
                                    <Col xs={10}>
                                        <Row>
                                            <Col xs={12} sm={8} lg={6}>
                                                <h2 className={this.state.openSections[id]?"closed":"open"}>{this.groupedByTopics && <FontAwesomeIcon icon={faDiagramProject} />} {topicId}</h2>
                                            </Col>
                                            <Col xs={12} sm={4} lg={6} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                                <WorksheetListStateBadges worksheets={this.state.worksheets[topicId]} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={2}>
                                        <div align={"right"} style={{paddingRight:'1em'}}>
                                            <div align={"right"} style={{paddingRight:'1em',fontSize:'1.5em'}}>
                                                {this.state.openSections[id]?
                                                    <FontAwesomeIcon icon={faCaretUp} />:
                                                    <FontAwesomeIcon icon={faCaretDown} />}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <Progress fontSize={"0.2em"} noText={true} bgColor={"#021a91"} worksheets={this.state.worksheets[topicId]}></Progress>
                        </Card.Header>
                        <Accordion.Collapse eventKey={id}>
                            <Card.Body>
                                {this.renderTable(topicId)}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </>
        )
    }

    getTopicName(topicId){
        let name = "";
        this.props.allTopics.map((topic,idx)=>{
            if(topic.projectId == topicId){
                name = topic.name;
            }
        });
        return name;
    }

    // Methode, um das Toast-Overlay zu schließen

    render() {
        if(this.state.isFetching){
            return <LoadingScreen/>;
        }
        if(this.topicId == null){
            return (
                <div className={"worksheetTableAccordion"}>
                    {Object.keys(this.state.worksheets).map((topicId, sectionIndex) => {
                        var id = "id" + topicId;
                        this.state.worksheets[topicId] = this.state.worksheets[topicId].sort((a, b) => a.trainee.name.localeCompare(b.trainee.name))
                        return (
                            this.renderAccordionWithTable(id,topicId)
                        );
                    })}
                </div>
            )
        }else{
            return (
                <div className={"pradleAccordion single"}>
                    <Container>
                        {this.renderTable(this.topicId)}
                    </Container>
                </div>
            )
        }
    }
}


export default WorksheetTableAccordion;