import React, { Component } from 'react';
import logo from "../../assets/bauabc_logo.PNG";
import PageLayout from "./PageLayout";

class PageNotFound extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
                <div className="App">
                    <header className="App-header">
                        <img src={logo} className="App-logo" alt="logo" />
                        <p>
                            Inhalt nicht gefunden
                        </p>
                        <a
                            className="App-link"
                            href="http://my.ausbildung.cloud/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            my.ausbildung.cloud
                        </a>
                    </header>
                </div>
        )
    }
}


export default PageNotFound;