import React, {Component, useEffect, useState} from 'react';
import {Button, Card, Col, Container, Form, NavLink, Row} from "react-bootstrap";
import './ActivateAccount.css'
import logo from "../../assets/bauabc_logo.PNG";
import {Buffer} from 'buffer';
import * as formik from 'formik';
import * as yup from 'yup';

import APIController from "../../controller/APIController";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHourglassStart} from "@fortawesome/free-solid-svg-icons";
import UserController from "../../controller/UserController";




const VerifyTANFormComponent = (props) => {
    const { Formik } = formik;
    const schema = yup.object().shape({
        tan: yup.string().required("TAN darf nicht leer sein")
    });

    const [initialValues, setInitialValues] = React.useState({ tan: decodeURI(props.state.tan)});
    const [apiResponseMessage, setApiResponseMessage] = useState("")
    const [inProgress, setInProgress] = useState(false);
    const [alertType, setAlertType] = useState("alert-danger");

    useEffect(() => {
        const errorMessageCleaner = setInterval(() => {
            if(apiResponseMessage !== ""){
                setApiResponseMessage("");
                setAlertType("alert-danger");
            }
        }, 5000);
        return () => clearInterval(errorMessageCleaner);
    }, [apiResponseMessage, alertType]);

    return (
        <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={ async (values, { resetForm }) => {
                try {

                    setApiResponseMessage("");
                    setInProgress(true);

                    let payLoadTAN = encodeURIComponent(values.tan);
                    let isTANValid = await APIController.verifyTAN(payLoadTAN);
                    if(isTANValid.valid){
                        setAlertType("alert-success");
                        setApiResponseMessage("Eingegebene TAN ist gültig.")
                        resetForm();
                        let stepInterval = setInterval(()=>{
                            props.process_step_handler({
                                stepInterval: stepInterval,
                                process_step: 1,
                                tan: values.tan,
                                isTanValid: isTANValid.valid,
                            })
                            setInProgress(false);
                        }, 2000);
                    }
                }
                catch (error){
                    resetForm();
                    setInProgress(false);
                    setApiResponseMessage(error.message);
                }
            }}>
            {({ handleSubmit, setFieldValue, values, handleBlur, touched, errors}) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label className="text-center">
                            Bitte persönliche TAN eingeben
                        </Form.Label>
                        <Form.Control type="text" placeholder="TAN" id="tan" value={values.tan}
                                      onBlur={handleBlur}
                                      isInvalid={touched.tan && !!errors.tan}
                                      onChange={(e) => setFieldValue( "tan",e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" >
                            {errors.tan}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {apiResponseMessage &&
                        <div className={"mt-3 mb-3 alert " + alertType }>{apiResponseMessage}</div>
                    }
                    <div className="d-grid">
                        <div align={'center'}>
                            <div style={{display: 'inline-block', marginRight: '0.5em'}}>
                                {!inProgress ?
                                    <Button
                                        type="submit"
                                        variant="outline-secondary">
                                        Weiter
                                    </Button> :
                                    <span>Bitte warten <FontAwesomeIcon icon={faHourglassStart}/></span>
                                }
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const VerifyEmailFormComponent = (props) => {
    // clear intervals from previous step before rendering new component
    clearInterval(props.state.stepInterval)

    // Maybe stepValidation

    /*if(!(props.state.process_step === 1 && props.state.tan && props.state.isTanValid)){
        props.process_step_handler({
            process_step: 0,
            tan: props.state.tan,
        })
    }*/
    const { Formik } = formik;
    const schema = yup.object().shape({
        email: yup.string().email("muss eine gültige E-Mail-Adresse sein").required("Email kann nicht leer sein")
    });

    const [initialValues, setInitialValues] = React.useState({ email: ""});
    const [apiResponseMessage, setApiResponseMessage] = useState("")
    const [inProgress, setInProgress] = useState(false);
    const [alertType, setAlertType] = useState("alert-danger");

    useEffect(() => {
        const errorMessageCleaner = setInterval(() => {
            if(apiResponseMessage !== ""){
                setApiResponseMessage("");
                setAlertType("alert-danger");
            }
        }, 5000);
        return () => clearInterval(errorMessageCleaner);
    }, [apiResponseMessage, alertType]);


    return (
        <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={ async (values, { resetForm }) => {
                try {

                    setApiResponseMessage("");
                    setInProgress(true);

                    let payLoadEmail = encodeURIComponent(values.email);
                    let payLoadTan = props.state.isTanValid && props.state.tan && props.state.isTanValid === true && props.state.tan !== "" ? props.state.tan : null;
                    let isVerificationCodeSent = await APIController.requestVerificationCode(payLoadEmail, payLoadTan);
                    if(isVerificationCodeSent.sent){
                        setAlertType("alert-info")
                        setApiResponseMessage( `Ein Bestätigungscode wurde an die angegebene E-Mail-Adresse ${values.email} gesendet.`);
                        resetForm();
                        let stepInterval = setInterval(()=>{
                            props.process_step_handler({
                                stepInterval: stepInterval,
                                process_step: 2,
                                tan: props.state.tan,
                                isTanValid: props.state.isTanValid,
                                email: values.email,
                                isVerificationCodeSent: isVerificationCodeSent.sent
                            })
                            setInProgress(false);
                        }, 2000);
                    }
                    else{
                        setInProgress(false);
                        setAlertType('alert-error');
                        setApiResponseMessage('Unknown error')
                    }
                }
                catch (error){
                    resetForm();
                    setInProgress(false);
                    setApiResponseMessage(error.message);
                }
            }}>
            {({ handleSubmit, setFieldValue, values, handleBlur, touched, errors}) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label className="text-center">
                            Unter welcher E-Mail Adresse bist du erreichbar?
                        </Form.Label>
                        <Form.Control type="email" placeholder="E-Mail-Adresse" id="email" value={values.email}
                                      onBlur={handleBlur}
                                      isInvalid={touched.email && !!errors.email}
                                      onChange={(e) => setFieldValue( "email",e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" >
                            {errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {apiResponseMessage &&
                        <div className={"mt-3 mb-3 alert " + alertType }>{apiResponseMessage}</div>
                    }
                    <div className="d-grid">
                        <div align={'center'}>
                            <div style={{display: 'inline-block', marginRight: '0.5em'}}>
                                {!inProgress ?
                                    <Button
                                        type="submit"
                                        variant="outline-secondary">
                                        Code per E-Mail senden
                                    </Button> :
                                    <span>Bitte warten <FontAwesomeIcon icon={faHourglassStart}/></span>
                                }
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const VerifyCodeFormComponent = (props) => {
    // clear intervals from previous step before rendering new component
    clearInterval(props.state.stepInterval)

    const { Formik } = formik;
    const schema = yup.object().shape({
        code: yup.string().min(6).max(6).required("Code kann nicht leer sein")
    });

    const [initialValues, setInitialValues] = React.useState({ code: props.state.code});
    const [apiResponseMessage, setApiResponseMessage] = useState("")
    const [inProgress, setInProgress] = useState(false);
    const [alertType, setAlertType] = useState("alert-danger");

    useEffect(() => {
        const errorMessageCleaner = setInterval(() => {
            if(apiResponseMessage !== ""){
                setApiResponseMessage("");
                setAlertType("alert-danger");
            }
        }, 5000);
        return () => clearInterval(errorMessageCleaner);
    }, [apiResponseMessage, alertType]);


    return (
        <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={ async (values, { resetForm }) => {
                try {

                    setApiResponseMessage("");
                    setInProgress(true);

                    let payLoadEmail = encodeURIComponent(props.state.email);
                    // fetch from url or get from step states
                    let payLoadBody = {
                        code : values.code,
                        tan: props.state.isTanValid && props.state.tan && props.state.isTanValid === true && props.state.tan !== "" ? props.state.tan : ""
                    }
                    let isCodeVerified = await APIController.verifyVerificationCode(payLoadEmail, payLoadBody);
                    if(isCodeVerified.valid){
                        setAlertType("alert-success")
                        setApiResponseMessage( `Eingegebener Code erfolgreich verifiziert`);
                        resetForm();
                        let stepInterval = setInterval(()=>{
                            props.process_step_handler({
                                stepInterval: stepInterval,
                                process_step: 3,
                                tan: props.state.tan,
                                isTanValid: props.state.isTanValid,
                                email: props.state.email,
                                isVerificationCodeSent: props.state.isVerificationCodeSent,
                                isCodeVerified: isCodeVerified.valid
                            })
                            setInProgress(false);
                        }, 2000);
                    }
                    else {
                        setInProgress(false);
                        setAlertType('alert-error');
                        setApiResponseMessage('Ungültig code');
                    }
                }
                catch (error){
                    resetForm();
                    setInProgress(false);
                    setApiResponseMessage(error.message);
                }
            }}>
            {({ handleSubmit, setFieldValue, values, handleBlur, touched, errors}) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label className="text-center">
                            Code eingeben
                        </Form.Label>
                        <Form.Control type="text" placeholder="Code eingeben" id="code" value={values.code}
                                      onBlur={handleBlur}
                                      isInvalid={touched.code && !!errors.code}
                                      onChange={(e) => setFieldValue( "code",e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" >
                            {errors.code}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {apiResponseMessage &&
                        <div className={"mt-3 mb-3 alert " + alertType }>{apiResponseMessage}</div>
                    }
                    <div className="d-grid">
                        <div align={'center'}>
                            <div style={{display: 'inline-block', marginRight: '0.5em'}}>
                                {!inProgress ?
                                    <Button
                                        type="submit"
                                        variant="outline-secondary">
                                        Code überprüfen
                                    </Button> :
                                    <span>Bitte warten <FontAwesomeIcon icon={faHourglassStart}/></span>
                                }
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const ChangePasswordFormComponent = (props) => {
    // clear intervals from previous step before rendering new component
    clearInterval(props.state.stepInterval)

    const { Formik } = formik;
    const schema = yup.object().shape({
        password: yup.string()
            .required("Passwort wird benötigt")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Muss 8 Zeichen enthalten, einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen"
            ),
        confirmPassword : yup.string()
            .required("Dieses Feld ist erforderlich")
            .oneOf([yup.ref('password'), null], "Die Passwörter müssen übereinstimmen")
    });

    const [initialValues, setInitialValues] = React.useState({ password: "", confirmPassword: ""});
    const [apiResponseMessage, setApiResponseMessage] = useState("")
    const [inProgress, setInProgress] = useState(false);
    const [alertType, setAlertType] = useState("alert-danger");

    useEffect(() => {
        const errorMessageCleaner = setInterval(() => {
            if(apiResponseMessage !== ""){
                setApiResponseMessage("");
                setAlertType("alert-danger");
            }
        }, 5000);
        return () => clearInterval(errorMessageCleaner);
    }, [apiResponseMessage, alertType]);


    return (
        <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={ async (values, { resetForm }) => {
                try {

                    setApiResponseMessage("");
                    setInProgress(true);

                    let isAccountSetupFinished = await APIController.resetInitialEmailAndInitialPassword(props.state.email, props.state.tan, values.password);

                    if(isAccountSetupFinished.status){
                        setAlertType("alert-success")
                        setApiResponseMessage( `Du hast dein Konto erfolgreich eingerichtet.`);
                        resetForm();
                        let stepInterval = setInterval(()=>{
                            props.process_step_handler({
                                stepInterval: stepInterval,
                                process_step: 4,
                                tan: props.state.tan,
                                isTanValid: props.state.isTanValid,
                                email: props.state.email,
                                isVerificationCodeSent: props.state.isVerificationCodeSent,
                                isCodeVerified: props.state.isCodeVerified,
                                password: values.password,
                                isAccountSetupFinished: isAccountSetupFinished.status,
                                loginSession: isAccountSetupFinished.loginSession
                            })
                            setInProgress(false);
                        }, 2000);
                    }
                }
                catch (error){
                    resetForm();
                    setInProgress(false);
                    setApiResponseMessage(error.message);
                }
            }}>
            {({ handleSubmit, setFieldValue, values, handleBlur, touched, errors}) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label className="text-center">
                            Lege ein neues Passwort fest
                        </Form.Label>
                        <Form.Control type="password" placeholder="Bestätige das Passwort" id="password" value={values.password}
                                      onBlur={handleBlur}
                                      isInvalid={touched.password && !!errors.password}
                                      onChange={(e) => setFieldValue( "password",e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" >
                            {errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="text-center">
                            Bestätige das Passwort
                        </Form.Label>
                        <Form.Control type="password" placeholder="Passwort eingeben" id="confirmPassword" value={values.confirmPassword}
                                      onBlur={handleBlur}
                                      isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                                      onChange={(e) => setFieldValue( "confirmPassword",e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" >
                            {errors.confirmPassword}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {apiResponseMessage &&
                        <div className={"mt-3 mb-3 alert " + alertType }>{apiResponseMessage}</div>
                    }
                    <div className="d-grid">
                        <div align={'center'}>
                            <div style={{display: 'inline-block', marginRight: '0.5em'}}>
                                {!inProgress ?
                                    <Button
                                        type="submit"
                                        variant="outline-secondary">
                                        Passwort festlegen
                                    </Button> :
                                    <span>Bitte warten <FontAwesomeIcon icon={faHourglassStart}/></span>
                                }
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const FinalStepRedirectComponent = (props) => {
    // clear intervals from previous step before rendering new component
    clearInterval(props.state.stepInterval);

    if(props.state.loginSession && props.state.loginSession.sessionToken !== ""){
        UserController.loginLocalUser(
            props.state.loginSession.sessionToken,
            props.state.loginSession.type,
            props.state.loginSession.email,
            props.state.loginSession.id
        );
        window.location = UserController.getMyCoursesUrl();
    }

    return (
        <div align={"center"}> Redirecting...</div>
    )

}

class ActivateAccount extends Component{
    constructor(props){
        super(props);
        if(UserController.isLoggedIn()){

            window.location = UserController.getMyCoursesUrl();
        }
        this.params = props.params;
        let step_nuance = new URLSearchParams(Buffer.from(this.params.get('step_nuance') !== null ? this.params.get('step_nuance') : "", 'base64').toString('ascii'))

        let tan = this.params.get('tan') !== null ? this.params.get('tan') : step_nuance.get('tan')!== null ? step_nuance.get('tan') : ''
        let email = step_nuance.get('email') !== null ? step_nuance.get('email') : '';
        let code = step_nuance.get('code') !== null ? step_nuance.get('code') : '';
        let process_step = tan && email && code ? 2 :0
        let isTanValid = step_nuance.get('tan') !== null && step_nuance.get('tan') !== '';
        let isVerificationCodeSent = !!email
        this.state = {
            tan: tan,
            email: email,
            code: code,
            process_step : process_step,
            isTanValid: isTanValid,
            isVerificationCodeSent: isVerificationCodeSent,
        }

    }

    process_step_handler = (newStateValue) => {
        this.setState(newStateValue);
    }

    stepMessageRenderer(step){
        switch (step) {
            case 0: return "Aktiviere dein Konto für das Digitale Arbeitsblatt";
            case 1: return "Wir benötigen eine E-Mail Adresse um die Account-Aktivierung abzuschließen. Bitte trage deine private E-Mail Adresse ein, sodass wir diese mit deinem Bau-ABC-Konto verknüpfen können.";
            case 2: return "Wir haben dir einen Bestätigungscode per E-Mail zugesendet. Bitte öffne dein E-Mail Postfach um deine E-Mail-Adresse zu bestätigen";
            case 3: return "Die Account Aktivierung ist fast abgeschlossen. Bitte wähle noch dein persönliches Passwort zum Einloggen";
            case 4: return "Großartig, du hast deinen Account erfolgreich aktiviert."
        }
    }

    render() {
        return (
            <div className="Activate-account">
                <Container>
                    <Row className="vh-100 d-flex justify-content-center align-items-center">
                        <Col md={8} lg={6} xs={12}>
                            <Card className="shadow">
                                <Card.Body>
                                    <div align={'center'}>
                                        <img src={logo} style={{height: "80px"}} className="App-logo" alt="logo"/>
                                    </div>
                                    <div className="mb-3 mt-md-4">
                                        <div align={'center'}>
                                            <h4 className="fw-bold mb-4"><b>{this.stepMessageRenderer(this.state.process_step)}</b>
                                            </h4>
                                        </div>
                                        <div className="mb-3 mt-md-4">
                                            {this.state.process_step === 0 ?
                                                (<VerifyTANFormComponent state={this.state} process_step_handler={this.process_step_handler}/>)
                                                :
                                                this.state.process_step === 1 ?
                                                    (<VerifyEmailFormComponent state={this.state} process_step_handler={this.process_step_handler}/>)
                                                    :
                                                    this.state.process_step === 2 ?
                                                        (<VerifyCodeFormComponent state={this.state} process_step_handler={this.process_step_handler}/>)
                                                        :
                                                        this.state.process_step === 3 ?
                                                            (<ChangePasswordFormComponent state={this.state} process_step_handler={this.process_step_handler}/>)
                                                            :
                                                            (<FinalStepRedirectComponent state={this.state}/>)
                                            }
                                        </div>
                                    </div>
                                </Card.Body>
                                <Card.Footer align={'end'} style={{backgroundColor: 'unset',borderTop:'unset'}}> <NavLink href={"/login"}> <span className={'text-decoration-underline'} >Hast du bereits ein Konto?</span></NavLink></Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default ActivateAccount