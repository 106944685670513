import React, {Component} from 'react';
import loading from "../assets/loadingicon_blue.gif";

class LoadingIcon extends Component {
    constructor(props) {
        super(props);
        this.style = this.props.style?this.props.style:{};
    }
    render() {
        return(
            <div className="LoadingIcon">
                <img style={this.style} src={loading} className="loadingImage" />
            </div>
        )
    }
}
export default LoadingIcon;