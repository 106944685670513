import React, {Component} from 'react';

import './CenteredMessage.css'
class CenteredMessage extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <div className="CenteredMessage">
                <div className={"CenteredMessageWrapper"}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}
export default CenteredMessage;