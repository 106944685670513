import React, {Component} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
class NumberInputFormField extends Component {
    constructor(props) {
        super(props);
        this.title = props.title;
        this.description = props.description;
        this.maxValue = props.steps ? props.maxValue / props.steps : props.maxValue;
        this.suffix = props.suffix;
        this.prefix = props.prefix;
        this.inputFieldProps = props.inputFieldProps;

        this.state = {
            value: props.value
        };
        this.steps = props.steps ? props.steps:1;

        this._handleChange = this._handleChange.bind(this);
        this.handleChange = props.onChange;
        this.selectInput = React.createRef();

    }


    _handleChange(){
        this.setState({ value:this.selectInput.current.value });
        this.handleChange(this.selectInput.current.value);
    }


    renderFormField(){
        return (
            <Form.Select {...this.inputFieldProps} value={this.state.value} ref={this.selectInput} onChange={this._handleChange} id="disabledSelect">
                {[...Array(this.maxValue)].map((x, i) => {
                    return(
                        <option key={i} value={(i+1)*this.steps}
                                defaultValue={(this.state.value === (i+1)*this.steps)?" selected ":""}>
                            {this.prefix} {(i+1)*this.steps} {this.suffix}
                        </option>
                    )
                })}
            </Form.Select>
        )
    }

    render() {
        if(this.title == null && this.description == null){
            return this.renderFormField()
        }else{
            return(
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="disabledSelect">{this.title}</Form.Label>
                    {this.renderFormField()}
                    <Form.Text className="text-muted">
                        {this.description}
                    </Form.Text>
                </Form.Group>
            )
        }
    }
}
export default NumberInputFormField;