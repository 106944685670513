import React, {Component} from 'react';

import {Badge} from "react-bootstrap";
import InstructorBadge from "./InstructorBadge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBox, faUserTie, faWarning} from "@fortawesome/free-solid-svg-icons";

class InstructorBadges extends Component {
    constructor(props) {
        super(props);
        this.instructors = props.instructors;
        this.smallSize = props.smallSize?props.smallSize:false;
        this.limit = props.limit != undefined?props.limit:10;
    }
    render() {
        return(
            <>
                {this.instructors.reduce((result, instructor, i) => {
                    if (i < this.limit ) {
                        result.push(<InstructorBadge key={i} instructor={instructor}></InstructorBadge>);
                    }
                    return result;
                }, [])}
                {this.instructors.length > this.limit  &&
                    <Badge bg={"dark"} style={{marginRight:'0.2em',marginBottom:'0.2em'}}>
                        {this.limit == 0 ?
                            <span><FontAwesomeIcon icon={faUserTie} /> {this.instructors.length} {this.smallSize?'':"Ausbilder"}</span>
                            :
                            <span>+{this.instructors.length - this.limit} {this.smallSize?'':"weitere Ausbilder"} <FontAwesomeIcon icon={faUserTie} /></span>
                        }
                    </Badge>                }
                {this.instructors.length == 0 &&
                    <Badge bg={"dark"} style={{marginRight:'0.2em',marginBottom:'0.2em'}}
                           className={"instructorBadge"} >
                        <FontAwesomeIcon icon={faWarning}/> {this.smallSize?'':"Keine Ausbilder"}
                    </Badge>
                }
            </>
        )
    }
}
export default InstructorBadges;