import React, {createRef} from 'react';
import './TableInput.css';
import {v4 as uuidv4} from "uuid";
import {Alert, Badge, Button, Col, Row} from "react-bootstrap";
import NumberInputFormField from "../formFields/NumberInputFormField";
import Dropzone from "react-dropzone";

import './FileUploadInput.css';
import MultiSelectInputFormField from "../formFields/MultiSelectInputFormField";
import ComponentHeader from "../text/ComponentHeader";
import InputComponent from "./InputComponent";
import TextInputFormField from "../formFields/TextInputFormField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faTrashCan, faUserTie} from "@fortawesome/free-solid-svg-icons";
import config from "../../config";
import APIController from "../../controller/APIController";
import UserController from "../../controller/UserController";

const allAcceptableFileTypes = [
    {
        id: uuidv4(),
        name: "PDF"
    },
    {
        id: uuidv4(),
        name: "PNG"
    },
    {
        id: uuidv4(),
        name: "Powerpoint"
    },
    {
        id: uuidv4(),
        name: "Excel"
    },
    {
        id: uuidv4(),
        name: "JPG"
    },
    {
        id: uuidv4(),
        name: "MP4"
    }
]

class FileUploadInput extends InputComponent {
    constructor(props) {
        super(props);
        this.component = props.component;
        this.writeModeBasedOnPhase = props.writeModeBasedOnPhase;
        this.dropzoneRef = createRef();
        this._handleChange = this._handleChange.bind(this);
        this.updateComponent = props.updateComponent;
        this.onDrop = this.onDrop.bind(this);
    }



    openDialog(){
        // Note that the ref is set async,
        // so it might be null at some point
        if (this.dropzoneRef.current) {
            this.dropzoneRef.current.open()
        }
    };

    static create(){
        return {
            id: uuidv4(),
            type : "fileUpload",
            title: "Datei Upload",
            data : {

            },
            config: {
                selectedAcceptedFiletypes: [
                    allAcceptableFileTypes[0],
                    allAcceptableFileTypes[4]
                ],
                minFilesCount : 1,
                maxFilesCount : 1,
                maxFilesSize : 10,
                files: []
            }

        };
    }

    _handleChange(id,value) {
        this.component.config[id] = value;
        this.updateComponent(this.component);
    };

    renderEditorView(){
        return (
            <div className={this.component.type}>
                <div>
                    <Row>
                        <Col xs={12} md={6}>
                            <MultiSelectInputFormField
                                title={"Erlaubte Dateitypen"}
                                description={"Welche Datei-Formate sind erlaubt?"}
                                options={allAcceptableFileTypes}
                                selectedValues={this.component.config.selectedAcceptedFiletypes}
                                onChange={(selectedValues) => {
                                    this.component.config.selectedAcceptedFiletypes = selectedValues;
                                    this.updateComponent(this.component);
                                }}
                                displayValue="name" // Property name to display in the dropdown options
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <NumberInputFormField
                                title={"Maximale Dateigröße"}
                                description={"Speichergröße, die eine Datei maximal haben darf"}
                                maxValue={100}
                                steps={10}
                                suffix={"MB"}
                                value={this.component.config.maxFilesSize}
                                onChange={(value) => this._handleChange('maxFilesSize',value)}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <NumberInputFormField
                                title={"Minimale Anzahl"}
                                description={"Anzahl an Dateien, die mindestens eingefügt werden müssen"}
                                maxValue={10}
                                suffix={""}
                                value={this.component.config.minFilesCount}
                                onChange={(value) => this._handleChange('minFilesCount',value)}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <NumberInputFormField
                                title={"Maximale Anzahl"}
                                description={"Anzahl an Dateien, die maximal eingefügt werden dürfen"}
                                maxValue={10}
                                suffix={""}
                                value={this.component.config.maxFilesCount}
                                onChange={(value) => this._handleChange('maxFilesCount',value)}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

        );
    }

    async onDrop(acceptedFiles){
        var self = this;
        for (const file of acceptedFiles) {
            let maxAllowedSize = this.component.config.maxFilesSize * 1024 * 1024;
            let fileResponse = await APIController.uploadFile(file,maxAllowedSize);
            if(fileResponse != null && fileResponse.url !== ""){
                self.component.config['files'].push(
                    fileResponse
                )
                self.updateComponent(self.component);
            }
        }
    }

    _updateFileName(component,fileId,newFileDescription){
        component.config.files.map( (file, idx) => {
            if(file.id == fileId){
                component.config.files[idx].description = newFileDescription;
            }
        });
    }

    _removeFileById(component,fileId){
        component.config.files.map( (file, idx) => {
            if(file.id == fileId){
                fetch(
                    `${config.API_HOST_URL}/files/${file.name}`,
                    {
                        method: "DELETE",
                    }
                ).then(function(response) {
                    return response.json();
                })
                component.config.files.splice(idx, 1);
            }
        });
    }

    renderInputView(){
        return (
            <div className={this.component.type}>
                <ComponentHeader
                    title={this.component.title}
                    description={this.component.config.description}
                    hasComments={this.component.config.commentFunctionChecked}
                    hasEvaluation={this.component.config.evaluationChecked}
                />
                <Dropzone onDrop={this.onDrop} ref={this.dropzoneRef} noClick noKeyboard disabled={!this.writeModeBasedOnPhase}>
                    {({getRootProps, getInputProps, acceptedFiles}) => {
                        return (
                            <div>

                                {(this.component.config['files'].length < this.component.config.minFilesCount) &&
                                    <div>
                                        <strong>Erst {this.component.config['files'].length}/{this.component.config.minFilesCount} Dateien hochgeladen!</strong>
                                    </div>
                                }

                                {(!(this.component.config['files'].length >= this.component.config.maxFilesCount)
                                ||
                                    this.component.config['files'].length < this.component.config.minFilesCount) &&
                                <div {...getRootProps({className: 'dropzone ' + (!this.writeModeBasedOnPhase ? 'disabled' : '')})}>
                                    <input {...getInputProps()} />
                                    <p>Drag & Drop Dateien hier</p>
                                    <button
                                        type="button"
                                        disabled={!this.writeModeBasedOnPhase}
                                        onClick={() => this.openDialog()}
                                    >
                                        Datei auswählen
                                    </button>
                                </div>
                                }
                                <br />
                                    {this.component.config['files'].map(file => (
                                        <Row key={file.id}>
                                            <Col className={'mb-1'} xs={12} lg={5}>
                                                <TextInputFormField
                                                    title={""}
                                                    rows={1}
                                                    placeholder={"Titel/Beschreibung"}
                                                    description={""}
                                                    onChange={(value) => {
                                                        this._updateFileName(this.component,file.id,value);
                                                        this.updateComponent(this.component);
                                                    }}
                                                    value={file.description}
                                                />
                                            </Col>
                                            <Col className={'mb-1'} xs={12} lg={2}>
                                                <Button style={{marginRight:'0.5em'}} onClick={() => {
                                                    window.location.href = file.url;
                                                }}>
                                                    <FontAwesomeIcon icon={faDownload} />
                                                </Button>
                                                <Button onClick={() => {
                                                    this._removeFileById(this.component,file.id);
                                                    this.updateComponent(this.component);
                                                }}>
                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                </Button>
                                            </Col>
                                            <Col className={'mb-1'} xs={12} lg={5}>
                                                {this.renderFileView(file)}
                                            </Col>
                                        </Row>
                                    ))}
                            </div>
                        );
                    }}
                </Dropzone>
                <div>
                    <Row>
                        <Col>
                            <h5>Erlaubte Dateitypen</h5>
                            {this.component.config.selectedAcceptedFiletypes.map((option, i) => {
                                return (
                                    <Badge style={{marginRight:'0.5em'}} bg="secondary">{option.name}</Badge>
                                )
                            })}
                        </Col>
                        <Col>
                            <h5>Maximale Dateigröße</h5>
                            {this.component.config.maxFilesSize}MB
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <h5>Minimal Dateianzahl</h5>
                            {this.component.config.minFilesCount}
                        </Col>
                        <Col>
                            <h5>Maximale Dateianzahl</h5>
                            {this.component.config.maxFilesCount}
                        </Col>
                    </Row>
                    <br />
                </div>
            </div>
        );
    }

    renderInstructorView(){
        return (
            <div className={this.component.type}>
                <div>
                    {(this.component.config['files'].length < this.component.config.minFilesCount) &&
                        <div>
                            <strong>Erst {this.component.config['files'].length}/{this.component.config.minFilesCount} Dateien hochgeladen!</strong>
                        </div>
                    }
                    <br />
                    {UserController.getUser().type === 'instructor' &&
                        <Alert variant="danger">Achtung! Hochgeladene <b>Dateien werden nicht automatisch auf Viren geprüft</b>. Seien Sie vorsichtig beim Öffnen der Dateien.</Alert>
                    }
                    {this.component.config['files'].map(file => (
                        <Row key={file.id}>
                            <Col className={'mb-1'} xs={12} lg={3}>
                                <Badge bg={"dark"}>{file.extension}</Badge> <span>{file.description}</span>
                            </Col>
                            <Col className={'mb-1'} xs={12} lg={9}>
                                {this.renderFileView(file)}
                            </Col>
                        </Row>
                    ))}
                </div>
            </div>
        );
    }

    renderFileView(file){
        if(file.type === "image/png"
            || file.type === "image/jpeg"
            || file.type === "image/jpg"){
            return <a title={"Bild öffnen"} href={file.url} target={'_blank'}><img src={file.url} style={{width:'100%'}}  alt={file.description}/></a>
        }else{
            return  <Button onClick={()=>{
                window.open(file.url)
            }
            }><FontAwesomeIcon icon={faDownload}/> Datei downloaden</Button>
        }
    }



    render() {
        if(this.props.instructorMode){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderInstructorView()}
            </div>
        } else if(this.props.showEditorView){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderEditorView()}
            </div>
        }else{
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderInputView(this.instructorMode)}
            </div>
        }
    }
}
export default FileUploadInput;