import React, {Component} from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import StatusBadge from "./StatusBadge";

class WorksheetListStateBadges extends Component {
    constructor(props) {
        super(props);
        this.worksheets = props.worksheets;
    }

    countStates(workingSheets){
        let DRAFT_WORKING_SHEET= 0;
        let OPEN_WORKING_SHEET= 0;
        let SUBMITTED_WORKING_SHEET= 0;
        let GRADED_WORKING_SHEET= 0;
        let DISCARDED_WORKING_SHEET= 0;

        workingSheets.map((w, sectionIndex) => {
            switch (w.state){
                case "DRAFT_WORKING_SHEET":
                    DRAFT_WORKING_SHEET++;
                    break;
                case "OPEN_WORKING_SHEET":
                    OPEN_WORKING_SHEET++;
                    break;
                case "SUBMITTED_WORKING_SHEET":
                    SUBMITTED_WORKING_SHEET++;
                    break;
                case "GRADED_WORKING_SHEET":
                    GRADED_WORKING_SHEET++;
                    break;
                case "DISCARDED_WORKING_SHEET":
                    DISCARDED_WORKING_SHEET++;
                    break;
            }
        });
        return {
            DRAFT_WORKING_SHEET:DRAFT_WORKING_SHEET,
            OPEN_WORKING_SHEET:OPEN_WORKING_SHEET,
            SUBMITTED_WORKING_SHEET:SUBMITTED_WORKING_SHEET,
            GRADED_WORKING_SHEET:GRADED_WORKING_SHEET,
            DISCARDED_WORKING_SHEET:DISCARDED_WORKING_SHEET,
        };
    }

    render() {
        if(!this.worksheets) return <></>;
        this.stateCounts = this.countStates(this.worksheets);
        return(
            <div className={"WorksheetListStateBadges"}>
                {this.stateCounts.SUBMITTED_WORKING_SHEET > 0 &&
                    <span style={{marginRight:'0.5em'}}><FontAwesomeIcon style={{color:'white'}}
                                                                         icon={StatusBadge.TYPES.SUBMITTED_WORKING_SHEET.icon}/> {this.stateCounts.SUBMITTED_WORKING_SHEET}</span>
                }
                {this.stateCounts.DISCARDED_WORKING_SHEET > 0 &&
                    <span style={{marginRight:'0.5em'}}><FontAwesomeIcon style={{color:'white'}}
                                                                         icon={StatusBadge.TYPES.DISCARDED_WORKING_SHEET.icon}/> {this.stateCounts.DISCARDED_WORKING_SHEET}</span>
                }
                {this.stateCounts.DRAFT_WORKING_SHEET > 0 &&
                    <span style={{marginRight:'0.5em'}}><FontAwesomeIcon style={{color:'white'}}
                                                                         icon={StatusBadge.TYPES.DRAFT_WORKING_SHEET.icon}/> {this.stateCounts.DRAFT_WORKING_SHEET}</span>
                }
                {this.stateCounts.OPEN_WORKING_SHEET > 0 &&
                    <span style={{marginRight:'0.5em'}}><FontAwesomeIcon style={{color:'white'}}
                                                                         icon={StatusBadge.TYPES.OPEN_WORKING_SHEET.icon}/> {this.stateCounts.OPEN_WORKING_SHEET}</span>
                }
                {this.stateCounts.GRADED_WORKING_SHEET > 0 &&
                    <span style={{marginRight:'0.5em'}}><FontAwesomeIcon style={{color:'white'}}
                                                                         icon={StatusBadge.TYPES.GRADED_WORKING_SHEET.icon}/> {this.stateCounts.GRADED_WORKING_SHEET}</span>
                }
            </div>
        )
    }
}
export default WorksheetListStateBadges;