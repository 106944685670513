import React, {Component} from 'react';

import './WorksheetBadge.css'
import {Badge} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileWord} from "@fortawesome/free-solid-svg-icons";

class WorksheetBadge extends Component {
    constructor(props) {
        super(props);
        this.worksheet = props.worksheet;
    }
    render() {
        return(
            <Badge style={{marginRight:'0.2em',marginBottom:'0.2em'}}
                   className={"topicBadge"} bg="dark">
                <FontAwesomeIcon icon={faFileWord}/> {this.worksheet.title}
            </Badge>
        )
    }
}
export default WorksheetBadge;