import _ from "lodash";


class WorksheetUtil{
    static finaPhaseNameByPhaseId(worksheet, phaseId) {
        return _.get(
            _.find(
                _.uniqBy(
                    _.compact(
                        _.flatMap(worksheet.sections, 'phase')
                    ),
                    'pid'
                ),
                { pid: phaseId }
            ),
            'name'
        );
    }

    /**
     * This files array can be attached on worksheet or project or connectedTopic for example
     * @param filesArray
     * @returns {*}
     */
    static findPrimaryTitleImageUrl(filesArray) {
        if(filesArray.length > 0){
            let primaryIdx = filesArray.findIndex(ct => ct.isPrimary === true);
            let primaryImageUrl = null;
            if(primaryIdx !== undefined && primaryIdx !== -1){
                primaryImageUrl = filesArray[primaryIdx].url;
            }else{
                primaryImageUrl = filesArray[0].url;
            }
            return primaryImageUrl;
        }
        return null;
    }
}
export default WorksheetUtil;