import fetchIntercept from 'fetch-intercept';
import UserController from "../controller/UserController";

export const addSessionTokenToRequestHeader = () => {
    fetchIntercept.register({
        request(url, defaultConfig) {
            let config = defaultConfig || {};
            //console.log(config);
            config.headers = config.headers || {};
            if(!UserController.isLoggedIn()){
                throw new Error('error, can not add sessionToken to request header without active login session')
            }

            config.headers.Authorization = 'Bearer '+UserController.getUser().sessionToken;
            return [url, config];
        },

        requestError: function (error) {
            // Called when an error occured during another 'request' interceptor call
            return Promise.reject(error);
        },

        response: function (response) {
            // Modify the reponse object
            if(response.status === 401){
                alert(response.statusText);
                return UserController.logout();
            }
            return response;
        },

        responseError: function (error) {
            // Handle an fetch error
            return Promise.reject(error);
        },
    });
}