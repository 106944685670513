

class FileUtil{

    static getExtensionWhitelist(){
        return ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.svg', '.webp',
        '.mp3', '.wav', '.ogg', '.flac',
        '.mp4', '.mov', '.avi', '.webm', '.mkv', '.wmv',
        '.txt', '.csv',
        '.pdf','.xls','.xlsx','.fsprj'];
    }

    /**
     * Loads extension from filepath like '/home/test/file.exe'=> '.exe'
     * @param filePath
     */
    static getFileExtension(filename) {
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 1).toLowerCase();
    }

    /**
     * Checks if thefilepath like '/home/test/file.exe' has a dangerous filetype like '.exe'
     * @param filePath
     * @returns {boolean}
     */
    static isDangerousFile(filePath){
        let fileExtension = FileUtil.getFileExtension(filePath);
        const dangerousExtensions = [
            ".exe", ".bat", ".cmd", ".js", ".vbs", ".sh", ".scr", ".msi", ".dll"
        ];
        return dangerousExtensions.includes(fileExtension);
    }

}
export default FileUtil;