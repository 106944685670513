import React, {Component} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";

import Multiselect from 'multiselect-react-dropdown';

class SingleSelectInputFormField extends Component {
    constructor(props) {
        super(props);

        this.title = props.title;
        this.description = props.description;
        this.options = props.options; //[{text='',value=''},...]
        this.placeholder = props.placeholder?props.placeholder:"Auswählen";
        this.disabled = props.disabled ? props.disabled: false;
        if(this.options == null){
            throw new Error("options need to be set");
        }

        var selectedValue = null;
        props.options.map((option, idx) => {
            let tempSelectedValue = (props.selectedValue) ? props.selectedValue+"".replace(",",".") : null;
            if(option.value === tempSelectedValue){
                selectedValue = [props.options[idx]];
            }
        });

        this.state = {
            selectedValue: selectedValue,
            options: props.options
        };

        this.onChange = props.onChange;
        this._handleChange = this._handleChange.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onRemove = this.onRemove.bind(this);


    }

    _handleChange(){
    }

    onSelect(val){
        this.setState({ selectedValue:val});
        this.onChange(val[0]);
    }

    onRemove(){
        this.setState({ selectedValue:null});
        this.onChange({name: '', value: null});
    }

    renderFormField(){
        return (
            <Multiselect
                singleSelect={true}
                placeholder={this.placeholder}
                emptyRecordMsg={"Alles bereits ausgewählt"}
                options={this.state.options} // Options to display in the dropdown
                selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                onSelect={this.onSelect} // Function will trigger on select event
                onRemove={this.onRemove} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                disable={this.disabled}
            />
        );
    }

    render() {

        if(this.title == null && this.description == null){
            return <div style={{display:"inline-block",width:'100%'}} className={"SingleSelectInputFormField"}>{this.renderFormField()}</div>
        }else{
            return(
                <Form.Group className={"SingleSelectInputFormField"}>
                    {this.title && <Form.Label htmlFor="disabledSelect">{this.title}</Form.Label>}
                    <div suppressContentEditableWarning={true} contentEditable="false">
                        {this.renderFormField()}
                    </div>
                    {this.description &&
                        <Form.Text className="text-muted">
                            {this.description}
                        </Form.Text>
                    }
                </Form.Group>
            )
        }
    }
}
export default SingleSelectInputFormField;