import React, {Component} from "react";
import SingleSelectInputFormField from "../formFields/SingleSelectInputFormField";
import {
    faColumns,
    faTableCells,
    faWindowMaximize
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class ComponentSizeDropdown extends Component {
    constructor(props) {
        super(props);
        this.worksheet = props.worksheet;
        this.onChange = props.onChange;

        this.state = {
            selectedSize: props.selectedSize?props.selectedSize:'12',
            sizeOptions : [
                {name: <FontAwesomeIcon icon={faWindowMaximize} />, value: '12'},
                {name: <FontAwesomeIcon icon={faColumns} />, value: '6'},
                {name: <FontAwesomeIcon icon={faTableCells} />, value: '4'}
            ]
        }
    }

    static renderIcon(component) {
        switch (component.config.size) {
            case '12':
                return (<><FontAwesomeIcon icon={faWindowMaximize} /></>);
            case '6':
                return (<><FontAwesomeIcon icon={faColumns} /></>);
            case '4':
                return (<><FontAwesomeIcon icon={faTableCells} /></>);
            default:
                return (<></>)
        }
    }

    render() {
        var self = this;
            return (
                <>
                    <SingleSelectInputFormField
                        selectedValue={self.state.selectedSize}
                        options={self.state.sizeOptions}
                        onChange={(selectionOption) => {
                            self.state.selectedSize = selectionOption.value;
                            self.setState({selectedSize: self.state.selectedSize});
                            this.onChange(self.state.selectedSize);
                        }}
                    />
            </>
        )
    }

}

export default ComponentSizeDropdown;