import React, {Component} from 'react';

import CourseBadge from "./CourseBadge";
import {Badge} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBox, faDiagramProject, faWarning} from "@fortawesome/free-solid-svg-icons";

class CourseBadges extends Component {
    constructor(props) {
        super(props);
        this.courses = Object.values(props.courses);
        this.limit = props.limit != undefined?props.limit:10;
    }
    render() {
        return(
            <>
                {this.courses.reduce((result, course, i) => {
                    if (i < this.limit) {
                        result.push(<CourseBadge key={i} course={course}></CourseBadge>);
                    }
                    return result;
                }, [])}
                {this.courses.length > this.limit &&
                    <Badge style={{marginRight:'0.2em',marginBottom:'0.2em'}}>
                        {this.limit == 0 ?
                            <span><FontAwesomeIcon icon={faBox} /> {this.courses.length} Kurse</span>
                            :
                            <span>+{this.courses.length - this.limit} weitere Kurse <FontAwesomeIcon icon={faBox} /></span>
                        }
                    </Badge>
                }
                {this.courses.length == 0 &&
                    <Badge style={{marginRight:'0.2em',marginBottom:'0.2em'}}
                           className={"courseBadge"} bg="dark">
                        <FontAwesomeIcon icon={faWarning}/> Keine Kurse
                    </Badge>
                }
            </>
        )
    }
}
export default CourseBadges;