

class StringUtil{

    static shortenText(text, maxCharacters) {

        if(text==null){
            return "";
        }

        // Prüfen, ob der Text länger als 10 Zeichen ist
        if (text.length > maxCharacters) {
            return text.substring(0, maxCharacters) + '...';
        }
        return text; // Wenn der Text kürzer oder gleich 10 Zeichen ist, unverändert zurückgeben
    }

    static objectToHash(object) {
        const str = JSON.stringify(object); // Array in einen String umwandeln
        let hash = 5381;
        for (let i = 0; i < str.length; i++) {
            hash = (hash * 33) ^ str.charCodeAt(i);
        }
        return hash >>> 0; // Hashwert als positive Zahl zurückgeben
    }


}
export default StringUtil;