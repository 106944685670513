import React, {Component} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faCalendarWeek} from "@fortawesome/free-solid-svg-icons";
import './KWFilter.css'

class KWFilter extends Component {
    constructor(props) {
        super(props);
        this.weekNumber = props.weekNumber;
        this.location = props.location;
        this.year = props.year;
        this.dateTimespan = props.dateTimespan;
        this.onChange = props.onChange;
        this.style = props.style;
    }


    render() {

        const zeroPad = (num, places) => String(num).padStart(places, '0')
        let printedKWNumber = zeroPad(this.weekNumber,2);
        return(
            <div style={this.style} className={'kwFilter'}>
                <Row>
                    <Col xs={12} md={3} lg={2} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                        <h3 className={"desktopOnly"}>
                            <FontAwesomeIcon icon={faCalendarWeek}/> KW {printedKWNumber}
                        </h3>
                    </Col>
                    <Col xs={12} md={6} lg={9} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <Row>
                            <Col style={{display:'flex',justifyContent:'right',alignItems:'center'}}>
                                <Button onClick={()=>{
                                    let newWeekNumber = this.weekNumber-1;
                                    let newYearNumber = this.year;
                                    if(newWeekNumber === 0){
                                        newWeekNumber = 52;
                                        newYearNumber = newYearNumber - 1;
                                    }
                                    this.onChange(newWeekNumber,newYearNumber);
                                }
                                }><FontAwesomeIcon icon={faArrowLeft}/></Button>
                            </Col>
                            <Col style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <h3 style={{marginBottom:'0em',display:'inline-block',minWidth:'9em',textAlign:'center'}}>{this.dateTimespan}</h3>
                            </Col>

                            <Col style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                <Button onClick={()=>{
                                    let newWeekNumber = this.weekNumber+1;
                                    let newYearNumber = this.year;
                                    if(newWeekNumber > 52){
                                        newWeekNumber = 1;
                                        newYearNumber = newYearNumber + 1;
                                        this.year = newYearNumber;
                                    }
                                    this.onChange(newWeekNumber,newYearNumber);
                                }
                                }><FontAwesomeIcon icon={faArrowRight}/></Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={3} lg={1} style={{display:'flex',justifyContent:'right',alignItems:'center'}}>
                        <h3 className={"desktopOnly"}>
                            {this.year}
                        </h3>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default KWFilter;