import React, {Component} from 'react';

import SingleSelectInputFormField from "./formFields/SingleSelectInputFormField";

class PhaseSelectionBox extends Component {

    constructor(props) {
        super(props);
        this.worksheet = props.worksheet;
        this.onWorksheetUpdated = props.onWorksheetUpdated;
        let allOptions = [];
        this.worksheet.phases.forEach((phase,index) => {
            allOptions.push({
                name: phase.title,
                value: phase.id
            });
        })
        this.allOptions = allOptions;
        let selectedOption = this.allOptions.find(obj => obj.value === this.worksheet.phase);

        if(this.allOptions.length === 0){
            selectedOption = null;
        }else if(selectedOption === undefined){
            selectedOption = this.allOptions[0];
        }

        this.state = {
            selectedOption: selectedOption
        }
    }


    render() {

        if(this.allOptions.length === 0){
            return (<></>);
        }

        return(
            <>
                <SingleSelectInputFormField
                    selectedValue={this.state.selectedOption.value}
                    options={this.allOptions}
                    onChange={(selectionOption) => {
                        this.worksheet.phase = selectionOption.value;
                        this.onWorksheetUpdated(this.worksheet);
                    }}
                />
            </>
        )
    }
}
export default PhaseSelectionBox;